import { GeoWidget } from '../core/GeoWidget';

class CreateBezierCurve extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'CreateBelzierCurve';
        config.title = config.title || 'CreateBelzierCurve';
        config.class = config.class || 'create-belzier-curve';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._isListening = false;
        this._lastPoint = null;
        this._bezierFeature = new ol.Feature(null);
        this._mouseFeature = new ol.Feature(null);
        this._pointsList = [];

        this._sharpness = 0.85;
        this._resolution = 1000;


        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();


        });

    }

    _getUiTemplate() {

        return `
            <div class='m-0 p-3' style="max-width:400px">

                <p class='gb-measure-tip'>Clique em iniciar e selecione um conjunto de pontos no mapa.</p>
            
                <div class='row pt-1 pb-1 pl-3 pr-3'>
            
                    <div class="col-12">
            
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text">Suavização</div>
                            </div>
                            <input type="number" class="form-control" id="gb-sharpness-${this.id}" min="0" step="0.1" value="${this._sharpness}">
                        </div>
                    </div>
            
                    <div class="col-12">
            
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text">Resolução</div>
                            </div>
                            <input type="number" class="form-control" id="gb-resolution-${this.id}" min="1" value="${this._resolution}">
                        </div>
                    </div>
            
                </div>
            
                <div class='row pt-1 pb-1 pl-3 pr-3'>
            
                    <button id="gb-btn-${this.id}" class="btn btn-dark btn-block btn-sm">Selecionar Elementos</button>
            
                </div>
            
            </div>
        `;

    }


    _registerElements() {

        this._btnElement = document.getElementById(`gb-btn-${this.id}`);
        this._sharpnessElement = document.getElementById(`gb-sharpness-${this.id}`);
        this._resolutionElement = document.getElementById(`gb-resolution-${this.id}`);

    }

    _addInteractions() {

        this._source.addFeature(this._bezierFeature);


        this._select = new ol.interaction.Select();
        this.map.ol.addInteraction(this._select);

        this._selectedFeatures = this._select.getFeatures();

        this._dragBox = new ol.interaction.DragBox({
            condition: ol.events.conditionplatformModifierKeyOnly
        });

        this.map.ol.addInteraction(this._dragBox);

        this._dragBox.on('boxend', () => {
            // features that intersect the box geometry are added to the
            // collection of selected features

            // if the view is not obliquely rotated the box geometry and
            // its extent are equalivalent so intersecting features can
            // be added directly to the collection
            var rotation = this.map.ol.getView().getRotation();
            var oblique = rotation % (Math.PI / 2) !== 0;
            var candidateFeatures = oblique ? [] : this._selectedFeatures;
            var extent = this._dragBox.getGeometry().getExtent();
            this._source.forEachFeatureIntersectingExtent(extent, function (feature) {
                candidateFeatures.push(feature);
            });

            // when the view is obliquely rotated the box extent will
            // exceed its geometry so both the box and the candidate
            // feature geometries are rotated around a common anchor
            // to confirm that, with the box geometry aligned with its
            // extent, the geometries intersect
            if (oblique) {
                var anchor = [0, 0];
                var geometry = this._dragBox.getGeometry().clone();
                geometry.rotate(-rotation, anchor);
                var extent$1 = geometry.getExtent();
                candidateFeatures.forEach(function (feature) {
                    var geometry = feature.getGeometry().clone();
                    geometry.rotate(-rotation, anchor);
                    if (geometry.intersectsExtent(extent$1)) {
                        selectedFeatures.push(feature);
                    }
                });
            }

        });

        this._dragBox.on('boxstart', () => {
            this._selectedFeatures.clear();
        });

        this.map.ol.on('pointerup', () => {

            setTimeout(() => {



                this._drawBezier();



            }, 200)



        })


    }

    _initInternalLayer() {

        this._vectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });
        this._vectorLayer.setZIndex(99999);
        this._vectorLayer.getSource().addFeature(this._mouseFeature);

        map.ol.addLayer(this._vectorLayer);

    }

    _drawBezier() {

        if (this._selectedFeatures.getLength() > 0) {

            let geoJsonFormat = new ol.format.GeoJSON();
            let features = this._selectedFeatures.getArray();
            let pointFeatures = [];

            features.forEach(feature => {

                let featureType = feature.getGeometry().getType().toLocaleLowerCase();
                if (featureType === 'point') {

                    pointFeatures.push(feature);

                }

            });

            if (pointFeatures.length > 2) {

                let lineArray = pointFeatures.map(function (point) {
                    return point.getGeometry().getCoordinates();
                });
                let bezier = turf.bezierSpline(turf.lineString(lineArray), {
                    resolution: this._resolution,
                    sharpness: this._sharpness
                });
                let bf = geoJsonFormat.readFeatureFromObject(bezier);

                this._bezierFeature.setGeometry(bf.getGeometry());

            }

        }

    }

    _actionsHandler() {

        this._addInteractions();

    }


    _registerEvents() {

        this._btnElement.addEventListener('click', () => this._actionsHandler());
        this._sharpnessElement.addEventListener('change', () => {
            this._sharpness = parseFloat(this._sharpnessElement.value);
            this._drawBezier();

        });
        this._resolutionElement.addEventListener('change', () => {
            this._resolution = parseFloat(this._resolutionElement.value);
            this._drawBezier();
        });




    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { CreateBezierCurve };