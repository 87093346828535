import { GeoControl } from './GeoControl';

class GeoTool extends GeoControl {

	constructor(config) {
		super(config);
		this._isActive = false;
	}

	_restoreUI(){
		this.map.closeAllSubPanels();
	}

    get isActive() {
        return this._isActive;
	}
	
	set isActive(val) {
        this._isActive = val;
    }

	activate() {
		this._isActive = true;
	}

	deactivate() {
		this._isActive = false;
		if (this.parent && this.parent instanceof GeoBuilder.GeoSubPanel) {
			this.parent.deactivate();
		}
	}

}

export { GeoTool };