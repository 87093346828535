import { GeoButton } from '../core/GeoButton';

class ZoomIn extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Aproximar';
        config.class = config.class || 'gb-zoomin-control';
        super(config);
    }

    initialize() { }

    click() {
        let zoom = this.map.ol.getView().getZoom() + 1;
        this.map.ol.getView().setZoom(zoom);
    }

}

export { ZoomIn };