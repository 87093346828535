import { GeoWidget } from '../core/GeoWidget';

class ListMenu extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'ListMenu';
        config.title = config.title || 'Lista de camadas';
        config.class = config.class || 'gb-menu-control';
        config.docked = config.docked || true;
        config.dockPosition = 'left';
        config.untitled = true;

        super(config);

        this._itens = [];
        this._listElement = null;

        this.ui = this._buildUi();

        this.on('ready', () => {

            this._registerExternalFunction();

        });

    }

    initialize() {

        //this.ui = this._buildUi();

        

    }

    _buildUi() {

        this._listElement = document.createElement('ul');
        this._listElement.className = 'list-group w-100';
        this._listElement.style.cursor = 'pointer';

        return this._listElement;

    }

    addItem(item) {

        let itemElement = document.createElement('li');
        itemElement.className = 'list-group-item';
        itemElement.innerHTML = item.text;

        this._listElement.appendChild(itemElement);
        this._itens.push(itemElement);


        //Default Event
        itemElement.addEventListener('click', () => {

            this.deactivate();

        });

        //Handler Event
        itemElement.addEventListener('click', item.handler);

    }

    _registerExternalFunction(){

        this.map.getListMenu = () => { return this; };

    }


    activate() {

        this.show();

    }

    deactivate() {

        this.hide();
    }

}

export { ListMenu };