import { GeoWidget } from '../core/GeoWidget';

class CityBlockSplitter extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'CityBlockSplitter';
        config.title = config.title || 'CityBlockSplitter';
        config.class = config.class || 'city-block-splitter';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._cityBlockFeature = config.cityBlockFeature;
        this._lotFeature = config.lotFeature;
        this._source = config.source;
        this._modify = null;
        this._isDrawing = false;
        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._initInternalLayer();
            this._initInteractions();
            this._registerElements();
            this._registerEvents();

        });

    }

    _initInteractions() {

        this._draw = new ol.interaction.Draw({
            source: this._vectorLayer.getSource(),
            type: 'LineString'
        });

        this._modify = new ol.interaction.Modify({
            source: this._vectorLayer.getSource()
        });

        this._snap = new ol.interaction.Snap({
            source: this._source
        });

    }

    _initInternalLayer() {

        this._vectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 0, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0f0',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0f0'
                    })
                })
            })
        });
        this._vectorLayer.setZIndex(99999);
        this.map.ol.addLayer(this._vectorLayer);

    }

    _getUiTemplate() {

        return `
            <div class='m-0 p-3'>
                <div class="row">
                    <div class="col">
                    <a id="gb-limits-btn-${this.id}" href="#" class="btn btn-primary btn-sm active btn-block" role="button" >Criar limites dos lotes</a>
                    </div>
                </div>
               

                <div class="row pt-3">
                    <div class="col-12">
                        <a id="gb-clear-btn-${this.id}" href="#" class="btn btn-primary btn-sm active btn-block" role="button" >Limpar linhas guias</a>
                    </div>
                </div>

                <div class="row pt-3">
                    <div class="col-12">
                        <a id="gb-go-horse-btn-${this.id}" href="#" class="btn btn-primary btn-sm btn-block disabled" role="button" >Gerar divisão dos lotes</a>
                    </div>
                </div>
                               
            </div>
        `;

    }

    _registerElements() {

        this._btnCreateLines = document.getElementById(`gb-limits-btn-${this.id}`);
        this._btnClear = document.getElementById(`gb-clear-btn-${this.id}`);
        this._btnGoHorse = document.getElementById(`gb-go-horse-btn-${this.id}`);

    }

    _createLinesHandler() {

        if (this._isDrawing) {

            this._btnCreateLines.innerHTML = 'Criar limites dos lotes';
            this.map.ol.removeInteraction(this._draw);
            this.map.ol.removeInteraction(this._modify);
            this.map.ol.removeInteraction(this._snap);
            this._isDrawing = false;

        } else {

            this._btnCreateLines.innerHTML = 'Parar';
            this.map.ol.addInteraction(this._draw);
            this.map.ol.addInteraction(this._modify);
            this.map.ol.addInteraction(this._snap);
            this._isDrawing = true;

        }



    }

    _clearHandler() {

        this._vectorLayer.getSource().clear();

    }

    _processingHandler() {

        let format = new ol.format.GeoJSON();
        let features = this._vectorLayer.getSource().getFeatures();
        let buffereds = [];

        for (let i = 0; i < features.length; i++) {

            let feature = features[i];
            feature.getGeometry().transform(this.map.srid, 'EPSG:4326');

            let gFeature = format.writeFeatureObject(feature);
            let gBuffered = turf.buffer(gFeature,  0.05, {units: 'meters'})

            feature.getGeometry().transform('EPSG:4326', this.map.srid);
            buffereds.push(gBuffered);
            
        }

        let tFeatures = turf.featureCollection(buffereds);
        var dissolved = turf.dissolve(tFeatures);

        this._cityBlockFeature.getGeometry().transform(this.map.srid, 'EPSG:4326');
        let cbFeature = format.writeFeatureObject(this._cityBlockFeature);


        var difference = turf.difference(cbFeature, dissolved.features[0]);

        this._cityBlockFeature.getGeometry().transform('EPSG:4326', this.map.srid);

        this._vectorLayer.getSource().clear();
        let nFeature = format.readFeatureFromObject(difference);
        nFeature.getGeometry().transform('EPSG:4326', this.map.srid);
        this._vectorLayer.getSource().addFeature(nFeature);




    }

    _uiHandler(){

        if(this._vectorLayer.getSource().getFeatures().length > 0){

            this._btnGoHorse.classList.remove('disabled');
            this._btnGoHorse.classList.add('active');

        } else {

            this._btnGoHorse.classList.remove('active');
            this._btnGoHorse.classList.add('disabled');
        }


    }

    _registerEvents() {

        this._btnCreateLines.addEventListener('click', () => this._createLinesHandler());
        this._btnClear.addEventListener('click', () => this._clearHandler());
        this._btnGoHorse.addEventListener('click', () => this._processingHandler());

        this._vectorLayer.getSource().on('change', () => this._uiHandler());
        this._draw.on('drawend', () => this._uiHandler());

    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { CityBlockSplitter };