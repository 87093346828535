import { GeoControl } from './GeoControl';

class GeoSubPanel extends GeoControl {

	constructor(config) {

		config.class = config.class || 'gb-subpanel-control';


		super(config);

		this._isOpen = false;
		this._isActive = false;
		this._controls = [];
		this._subPanelElementId = null;
		this._controlElementId = null;
		this._isRunning = false;


	}

	get elementId() {
		return this._controlElementId;
	}

	set elementId(id) {
		this._controlElementId = id;
	}

	get subPanelElementId() {
		return this._subPanelElementId;
	}

	get isActive() {
		return this._isActive;
	}

	set isActive(status) {
		this._isActive = status;
	}

	get isOpen() {
		return this._isOpen;
	}

	set isOpen(status) {
		this._isOpen = status;
	}

	get isRunning() {
		return this._isRunning;
	}

	set isRunning(status) {
		this._isRunning = status;
	}

	initialize() { }

	registerControls() {

		for (let i = 0; i < this._controls.length; i++) {
			this.map.addControl(this._controls[i], this._subPanelElementId);
		}

	}

	addControl(control) {

		control.parent = this;
		this._controls.push(control);

	}

	createSubPanelElement() {

		this._subPanelElementId = `gb-subpanel-${this._config.position || this._config.panel}_${this.id}_${this.map.target}`;
		let subPanelElement = document.createElement('div');
		subPanelElement.className = `gb-subpanel gb-subpanel-${this._config.position || this._config.panel}`;
		subPanelElement.id = this._subPanelElementId;
		document.getElementById(this.map.elementId).append(subPanelElement);
		this.map.subPanels.push(this);

	}

	click() {

		if (this._isOpen) {
			this.deactivate();
		} else {
			this.activate();
		}

	}

	activate() {

		let controlElement = document.getElementById(this._controlElementId);
		let subPanelElement = document.getElementById(this._subPanelElementId);
		let top = controlElement.parentElement.offsetTop + controlElement.offsetTop;
		let left = 0;
		let right = 0;
		let onLeft = controlElement.parentElement.offsetLeft < (window.innerWidth / 2);
		left = controlElement.parentElement.offsetLeft + controlElement.clientWidth;
		right = this.map.clientSize - controlElement.parentElement.offsetLeft;

		if (onLeft) {

			subPanelElement.style.left = left + 'px';
			subPanelElement.style.right = 'auto';

		} else {

			subPanelElement.style.right = right + 'px';
			subPanelElement.style.left = 'auto';

		}

		subPanelElement.style.top = top + 'px';
		subPanelElement.style.display = 'inline-flex';
		controlElement.classList.add('gb-control-active');
		this._isOpen = true;

	}

	deactivate() {

		let controlElement = document.getElementById(this._controlElementId);
		let subPanelElement = document.getElementById(this._subPanelElementId);
		subPanelElement.style.display = 'none';
		controlElement.classList.remove('gb-control-active');
		controlElement.className = this.config.class + ' gb-control';
		this._closeAllControls();
		this._isOpen = false;

	}

	_closeAllControls() {

		if (this._isActive) {

			for (let i = 0; i < this._controls.length; i++) {

				if (this._controls[i].isActive) {
					this._controls[i].deactivate();
				}
			}

			this._isActive = false;
		}
	}


}

export { GeoSubPanel };