import { GeoTool } from '../core/GeoTool';

class StreetView extends GeoTool {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Google StreetView';
        super(config);

        this._drawPromise = null;

    }

    initialize() {

        this._builUi();

        this.streetViewLayer = new ol.layer.Vector({
            source: new ol.source.Vector()
        });
        this.streetViewLayer.setZIndex(99999);
        this.map.ol.addLayer(this.streetViewLayer);
        this.projection = this.map.ol.getView().getProjection().getCode();

        this.streetViewPanoramaStyle = new ol.style.Style({
            image: new ol.style.Circle({
                radius: 3,
                fill: new ol.style.Fill({
                    color: 'rgb(255,0,0)'
                }),
                stroke: new ol.style.Stroke({
                    color: 'rgb(255,0,0)',
                    width: 1
                })
            }),
            fill: new ol.style.Fill({
                color: 'rgba(255,0,0,.5)'
            }),
            stroke: new ol.style.Stroke({
                color: 'rgb(255,0,0)',
                width: 2
            })
        });

    }

    _builUi() {

        this._mapContainer = document.createElement('div');
        this._mapContainer.id = 'gb-lv-view-map' + this.id;
        this._mapContainer.className = 'gb-lv-view';
        this.map.mapElement.appendChild(this._mapContainer);

        // this._changeButtom = document.createElement('div');
        // this._changeButtom.className = 'gb-lv-change';
        // this._mapContainer.appendChild(this._changeButtom);

        // this._changeButtomTip = document.createElement('div');
        // this._changeButtomTip.className = 'gb-lv-change-tip';
        // this._changeButtomTip.innerHTML = 'Trocar Posição';
        // this._mapContainer.appendChild(this._changeButtomTip);

        this._streetViewContainer = document.createElement('div');
        this._streetViewContainer.id = 'gb-lv-view' + this.id;
        this._streetViewContainer.style.width = '100%';
        this._streetViewContainer.style.height = '100%';
        this._streetViewContainer.style.display = 'absolute';

        this._landViewDate = document.createElement('div');
        this._landViewDate.className = 'gb-lv-date';
        this.map.mapElement.appendChild(this._landViewDate);


        // this._changeButtom.addEventListener('mouseover', () => {
        //     this._changeButtomTip.style.display = 'block';
        // });

        // this._changeButtom.addEventListener('mouseout', () => {
        //     this._changeButtomTip.style.display = 'none';
        // });

        // this._changeButtom.addEventListener('click', () => {
        //     this._changePosition();
        // });

    }

    _setDefaultPanorama() {

        let center = this.config.initialLngLat ||
            ol.proj.transform(this.map.ol.getView().getCenter(), this.projection, 'EPSG:4326');

        this.panorama = new google.maps.StreetViewPanorama(
            this._streetViewContainer,
            {
                position: { lat: center[1], lng: center[0] },
                pov: { heading: 0, pitch: 0 },
                zoom: 1,
                addressControl: false,
                fullscreenControl: false,
                zoomControl: false,
                motionTrackingControl: false
            }
        );


    }

    getPanoramaMapView() {

        let p1 = [this.panorama.position.lng(), this.panorama.position.lat()];
        p1 = ol.proj.transform(p1, 'EPSG:4326', 'EPSG:3857');

        let radius = 15;
        let zoom = this.panorama.pov.zoom;
        let fov = Math.PI / Math.pow(2, zoom);
        let azimuth = this.panorama.pov.heading * Math.PI / 180;
        let p2 = [];
        let p3 = [];

        p2[0] = p1[0] + radius * Math.sin(azimuth + (fov / 2));
        p2[1] = p1[1] + radius * Math.cos(azimuth + (fov / 2));

        p3[0] = p1[0] + radius * Math.sin(azimuth - (fov / 2));
        p3[1] = p1[1] + radius * Math.cos(azimuth - (fov / 2));

        p1 = ol.proj.transform(p1, 'EPSG:3857', this.projection);
        p2 = ol.proj.transform(p2, 'EPSG:3857', this.projection);
        p3 = ol.proj.transform(p3, 'EPSG:3857', this.projection);

        let polygon = new ol.geom.Polygon([[p1, p2, p3]]);

        return new ol.Feature({
            geometry: polygon
        });

    }

    drawPanoramaPosition() {

        let pos = [this.panorama.position.lng(), this.panorama.position.lat()];
        pos = ol.proj.transform(pos, 'EPSG:4326', this.projection);

        let triangleFeature = this.getPanoramaMapView(pos, this.panorama);
        let pointFeature = new ol.Feature({
            geometry: new ol.geom.Point(pos)
        });

        pointFeature.setStyle(this.streetViewPanoramaStyle);
        triangleFeature.setStyle(this.streetViewPanoramaStyle);

        this.streetViewLayer.getSource().clear();
        this.streetViewLayer.getSource().addFeature(pointFeature);
        this.streetViewLayer.getSource().addFeature(triangleFeature);

        this.map.ol.getView().animate({
            center: pos,
            duration: 500
        });

        if(this._isMapClick){

            this._isMapClick = false;

            let p1 = [this.panorama.position.lng(), this.panorama.position.lat()];
            let pt = ol.proj.transform(this._clickPosition, this.projection, 'EPSG:3857');
            p1 = ol.proj.transform(p1, 'EPSG:4326', 'EPSG:3857');

            let heading = Math.atan2(pt[0] - p1[0], pt[1] - p1[1]) * 180 / Math.PI;

            this.panorama.setPov({
                heading: heading,
                pitch: this.panorama.pov.pitch
            });

        }

    }

    _mapClickEvent(evt) {

        let coord = evt.coordinate;
        let pos = ol.proj.transform(coord, this.projection, 'EPSG:4326');
        this.panorama.setPosition({ lat: pos[1], lng: pos[0] });

        this._isMapClick = true;
        this._clickPosition = coord;

    }

    _registerEvents() {



        this.panorama.addListener('position_changed', () => {

            this.drawPanoramaPosition();

        });

        this.panorama.addListener('pov_changed', () => {

            this.drawPanoramaPosition();

        });

        this.map.ol.on('click', (evt) => this._mapClickEvent(evt));


    }

    _unregisterEvents () {

		this.map.ol.un('click', (evt) => this._mapClickEvent(evt));

	}

    activate() {

        super.activate();

        this._mapContainer.style.display = 'block';
        this._landViewDate.style.display = 'block';
        this.map.ol.setTarget(this._mapContainer);
        this._isMiniMap = true;
        this.map.mapElement.appendChild(this._streetViewContainer);

        this._setDefaultPanorama();
        this._registerEvents();

    }

    deactivate() {

        this.streetViewLayer.getSource().clear();
        this.panorama = null;
        this.map.ol.setTarget(this.map.mapElement);
        this._mapContainer.style.display = 'none';
        this._mapContainer.innerHTML = '';
        this._streetViewContainer.innerHTML = '';
        //this._mapContainer.appendChild(this._changeButtom);
        //this._mapContainer.appendChild(this._changeButtomTip);
        this.map.mapElement.removeChild(this._streetViewContainer);
        this.map.showDisabledControls();

        super.deactivate();

    }

}

export { StreetView };