import { GeoTool } from '../core/GeoTool';

class MultiPolygonToPolygon extends GeoTool {

    constructor(config) {
        config.source = config.source || new ol.source.Vector();
        config.tip = config.tip || 'Transformar multpolygon para polygon';
        config.class = config.class || 'gb-multi-to-poly';
        super(config);
        this._source = config.source;
        this._select = null;
        this._selectClick = null;
    }

    initialize() { };

    activate() {
        super.activate();

        this._addSelectInteraction();

        document.getElementById(this.map.elementId).style.cursor = 'pointer';
    }

    _addSelectInteraction() {
        this._selectClick = new ol.interaction.Select({
          condition: ol.events.condition.click,
        });

        this._selectClick.on('select', (e) => {
          this._select = e.selected[0];
          this._transformFeature();
        });

        this.map.ol.addInteraction(this._selectClick);
    }

    _transformFeature() {
      if (this._select && this._select.getGeometry().getType() === 'MultiPolygon') {
        this._select.getGeometry().getPolygons().forEach(polygon => {
          const poly = new ol.geom.Polygon(polygon.getCoordinates().slice());
          const feat = new ol.Feature(poly);
          this._source.addFeature(feat);
        });
        this._source.removeFeature(this._select);
      }
    }

    deactivate() {
      document.getElementById(this.map.elementId).style.cursor = 'auto';
      this.map.ol.removeInteraction(this._selectClick);
      this._select = null;
      this._selectClick = null;

      super.deactivate();
    }
}

export { MultiPolygonToPolygon };