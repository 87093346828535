class GeoControl {

    constructor(config) {

        this.__id__ = GeoControl.internalId++;
        this._config = config || {};
        this._map = null;
        this._panel = null;
        this._defaultStyle = null;
        this._setDefaultValues();
        this._events = {
            ready: [],
            activate: []
        };

    }

    get map() {
        return this._map;
    }

    set map(newMap) {
        this._map = newMap;
    }

    get panel() {
        return this._panel;
    }

    set panel(panel) {
        this._panel = panel;
    }

    get id() {
        return this.__id__;
    }

    get config() {
        return this._config;
    }

    _setDefaultValues() {

        this._config.position = this._config.position || 'lt';
        this._config.tip = this._config.tip || '';
        this._config.class = this._config.class || 'gb-undefined-control';
        this._config.title = this._config.title || 'Widget';

    }

    _saveDefaultStyle() {

        this._defaultStyle = Object.assign({}, document.getElementById(this._elementId).style);

    }

    _restoreDefaultStyle() {

        document.getElementById(this._elementId).style = this._defaultStyle;

    }

    eventDispatcher(eventName, data) {

        this._verifyEventName(eventName);

        for (let i = 0; i < this._events[eventName].length; i++) {

            this._events[eventName][i](data);

        }

    }

    on(eventName, callback) {

        this._verifyEventName(eventName);
        this._events[eventName].push(callback);

    }

    un(eventName, callback) {

        this._verifyEventName(eventName);
        this._events[eventName] = this._events[eventName].filter(item => item !== callback);

    }

    _verifyEventName(eventName) {

        if (Object.keys(this._events).indexOf(eventName) === -1) {
            throw ('Event does not exist: "' + eventName + '".');
        }

    }

}

GeoControl.internalId = 1;

export { GeoControl };