import { GeoWidget } from '../core/GeoWidget';

class Cad extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'ct';
        super(config);

        this._dock = null;
        this._hasUI = false;
        this._drawingLayer = null;
        this._auxLayer = null;
        this._layers = {
            centroids: null,
            drawing: null,
            intersections: null,
            lines: null,
            midLines: null,
            orthoLines: null
        };
        this._snaps = [];
        this._units = 'px';
        this._tolerance = 50;
        this._toSegment = true;
        this._tovertex = true;
        this._maxResolution = 0;
        this._selectedFeaturesHover = null;
        this._selectedFeaturesClick = null;
        this._selectClickControl = null;
        this._isDrawing = false;
        this._toolsStatus = {
            auxLines: false,
            intersections: false,
            centroids: false,
            midPoints: false,
            ortho: false
        };
        this._promptFunction = null;
        this._promptTip = null;
        this.cmdList = [
            {
                title: 'Add a WKT feature to map',
                cmd: 'wkt',
                fnc: this._fromWktPrompt
            },
            {
                title: 'Create a buffer from a selected geometry',
                cmd: 'buffer',
                fnc: this._bufferPrompt
            },
            {
                title: 'Select a feature',
                cmd: 'select',
                fnc: this._selectPrompt
            },
            {
                title: 'Add a point',
                cmd: 'point',
                fnc: this._pointPrompt
            }

        ];
        
    }

    initialize() {

        this._initInterface();
        this._initLayers();
        this._setDefaultStyles();
        this._createSnapInteraction();
        this._initSelections();
        this._registerEvents();
        this._refreshStatus();

    }

    _initInterface() {

        this._dock = this.map.getDock('bottom');
        this._dock.innerHTML = this._template();
        this._auxDock = document.getElementById(`infobox_${this.id}`);
        this._auxDock.innerHTML = this._snapTemplate();
        this._auxDock.innerHTML += this._promptTemplate();

    }

    _template() {

        return `
            <div id="infobox_${this.id}" class="gb-info-box"> </div>
            <div class="gb-cad-container"> 
                <input class="gb-prompt" id="prompt_${this.id}" type="text" placeholder="Type a command...">
                <button id="addPoint_${this.id}">Point</button>
                <button id="addLine_${this.id}">Line</button>
                <button id="addPolygon_${this.id}">Polygon</button>
                <button id="addRing_${this.id}" disabled>Add Ring</button>
                <button id="removeRing_${this.id}" disabled>Remove Ring</button>
                <button id="addBuffer_${this.id}" disabled>Buffer</button>
                <button id="select_${this.id}">Select Feature</button>
                <button id="snap_${this.id}">Snap Tools</button>
            </div>
        `;

    }

    _snapTemplate() {

        return `
            <div id="snap-container_${this.id}" class="gb-cad-aux-container"> 
                <h5>Snap Config</h5>
                <input id="auxLines_${this.id}" type="checkbox" value="1">
                <label for="auxLines_${this.id}">Aux Lines</label><br>

                <input id="intersections_${this.id}" type="checkbox" value="1">
                <label for="intersections_${this.id}">Intersections</label><br>

                <input id="centroids_${this.id}" type="checkbox" value="1">
                <label for="centroids_${this.id}">Centroids</label><br>

                <input id="midPoints_${this.id}" type="checkbox" value="1">
                <label for="midPoints_${this.id}">Mid Points</label><br>

                <input id="ortho_${this.id}" type="checkbox" value="1">
                <label for="ortho_${this.id}">Ortho Lines</label><br>

            </div>
        `;
    }

    _promptTemplate() {
        return `
        <div id="prompt_container_${this.id}" class="gb-cad-aux-container"> 
        </div>
    `;
    }

    _uiHandler() {

        // Ring
        document.getElementById(`addRing_${this.id}`).setAttribute('disabled', '');
        document.getElementById(`removeRing_${this.id}`).setAttribute('disabled', '');
        document.getElementById(`addBuffer_${this.id}`).setAttribute('disabled', '');

        if (this._selectedFeaturesClick && this._selectedFeaturesClick.getArray().length > 0) {

            let selectedFeatures = this._selectedFeaturesClick.getArray();
            for (let i = 0; i < selectedFeatures.length; i++) {

                if (selectedFeatures[i].getGeometry().getType() == 'Polygon') {

                    // Ring
                    document.getElementById(`addRing_${this.id}`).removeAttribute('disabled');
                    document.getElementById(`removeRing_${this.id}`).removeAttribute('disabled');


                }

                document.getElementById(`addBuffer_${this.id}`).removeAttribute('disabled');

            }


        }



    }

    _refreshStatus() {

        document.getElementById(`auxLines_${this.id}`).checked = this._toolsStatus.auxLines;
        document.getElementById(`intersections_${this.id}`).checked = this._toolsStatus.intersections;
        document.getElementById(`centroids_${this.id}`).checked = this._toolsStatus.centroids;
        document.getElementById(`midPoints_${this.id}`).checked = this._toolsStatus.midPoints;
        document.getElementById(`ortho_${this.id}`).checked = this._toolsStatus.ortho;

        this._createAuxFeatures();

    }

    _initSelections() {

        var selectPointerMove = new ol.interaction.Select({
            condition: ol.events.condition.pointerMove,
            hitTolerance: 10,
            multi: true,
            layers: [
                this._layers.drawing,
                this._layers.lines
            ],
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 0, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: 'rgba(255, 0, 255, 1)',
                    width: 1,
                    lineDash: [5, 7]
                }),
                image: new ol.style.Circle({
                    radius: 2,
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 0, 255, 1)'
                    })
                })
            })
        });

        selectPointerMove.on('select', (e) => {

            this._selectedFeaturesHover = e.target.getFeatures();

        });

        this.map.ol.addInteraction(selectPointerMove);

        this._selectClickControl = new ol.interaction.Select({
            condition: ol.events.condition.singleClick,
            hitTolerance: 10,
            multi: false,
            layers: [
                this._layers.drawing
            ],
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 0, 255, 0.3)'
                }),
                stroke: new ol.style.Stroke({
                    color: 'rgba(0, 0, 255, 1)',
                    width: 1
                }),
                image: new ol.style.Circle({
                    radius: 2,
                    fill: new ol.style.Fill({
                        color: 'rgba(0, 0, 255, 1)'
                    })
                })
            })

        });

        this._selectClickControl.on('select', (e) => {


            this._selectedFeaturesClick = e.target.getFeatures();
            this._uiHandler();


        });

        this.map.ol.addInteraction(this._selectClickControl);
        this._selectClickControl.setActive(false);


    }

    _initLayers() {

        this._layers = {
            lines: new ol.layer.Vector({
                source: new ol.source.Vector(),
            }),
            intersections: new ol.layer.Vector({
                source: new ol.source.Vector(),
            }),
            drawing: new ol.layer.Vector({
                source: new ol.source.Vector(),
            }),
            midLines: new ol.layer.Vector({
                source: new ol.source.Vector(),
            }),
            centroids: new ol.layer.Vector({
                source: new ol.source.Vector(),
            }),
            orthoLines: new ol.layer.Vector({
                source: new ol.source.Vector(),
            })
        };

        this.map.ol.addLayer(this._layers.centroids);
        this.map.ol.addLayer(this._layers.drawing);
        this.map.ol.addLayer(this._layers.intersections);
        this.map.ol.addLayer(this._layers.lines);
        this.map.ol.addLayer(this._layers.midLines);
        this.map.ol.addLayer(this._layers.orthoLines);

    }

    _setDefaultStyles() {


        this._layers.drawing.setStyle(new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(255, 174, 66, 0.2)'
            }),
            stroke: new ol.style.Stroke({
                color: '#FFAE42',
                width: 1
            }),
            image: new ol.style.Circle({
                radius: 2,
                fill: new ol.style.Fill({
                    color: '#FFAE42'
                })
            })
        }));

        this._layers.drawing.setZIndex(999);

        this._layers.lines.setStyle(new ol.style.Style({
            stroke: new ol.style.Stroke({
                color: 'rgba(255, 255, 255, 0.2)',
                width: 0.5
            })
        }));

        this._layers.orthoLines.setStyle(new ol.style.Style({
            stroke: new ol.style.Stroke({
                color: 'rgba(255, 255, 255, 0.2)',
                width: 0.5
            })
        }));

        this._layers.centroids.setStyle(new ol.style.Style({
            image: new ol.style.RegularShape({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0)'
                }),
                stroke: new ol.style.Stroke({
                    color: 'rgba(255, 0, 255, 1)',
                    width: 0.5
                }),
                points: 3,
                radius: 6,
                angle: 0
            })
        }));

        this._layers.midLines.setStyle(new ol.style.Style({
            image: new ol.style.RegularShape({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0)'
                }),
                stroke: new ol.style.Stroke({
                    color: 'rgba(255, 0, 255, 1)',
                    width: 0.5
                }),
                points: 4,
                radius: 6,
                angle: Math.PI / 4
            })
        }));

        this._layers.intersections.setStyle(new ol.style.Style({
            image: new ol.style.Circle({
                radius: 2,
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                })
            })
        }));

    }



    _createSnapInteraction() {

        this._snaps = [];
        let resolution = this.map.ol.getView().getResolution();
        let pixelTolerance = this._units === 'px' ? parseInt(this._tolerance) : Math.round(parseInt(this._tolerance) / resolution);
        let layers = Object.keys(this._layers);

        for (let i = 0; i < layers.length; i++) {

            let snap = new ol.interaction.Snap({
                source: this._layers[layers[i]].getSource(),
                edge: this._toSegment,
                vertex: this._toVertex,
                pixelTolerance: pixelTolerance
            });

            this._snaps.push(snap);
            this.map.ol.addInteraction(snap);

        }

    }

    _refreshInteractions() {

        for (let i = 0; i < this._snaps.length; i++) {

            this.map.ol.removeInteraction(this._snaps[i]);
            this.map.ol.addInteraction(this._snaps[i]);

        }

    }


    _clearAuxFeatures() {

        this._layers.lines.getSource().clear();
        this._layers.intersections.getSource().clear();
        this._layers.centroids.getSource().clear();
        this._layers.midLines.getSource().clear();
        this._layers.orthoLines.getSource().clear();

    }

    _createAuxFeatures() {

        this._clearAuxFeatures();

        if (this._toolsStatus.auxLines) this._createAuxLines();
        if (this._toolsStatus.intersections) this._createIntersectionPoints();
        if (this._toolsStatus.midPoints) this._createMidPoints();
        if (this._toolsStatus.centroids) this._createCentroids();
        if (this._toolsStatus.ortho) this._createOrthoLines();

        this._refreshInteractions();

    }

    _createAuxLines() {

        let extent = this.map.ol.getView().calculateExtent();
        let features = this._layers.drawing.getSource().getFeatures();
        for (let i = 0; i < features.length; i++) {

            let feature = features[i];
            let coords = feature.getGeometry().getCoordinates();

            if (coords instanceof Array) { //coords is ok

                if (coords[0] instanceof Array) { //line or polygon

                    if (coords[0][0] instanceof Array) { // polygon

                        for (let k = 0; k < coords.length; k++) {

                            for (let l = 0; l < coords[k].length; l++) {

                                this._createOlLinesFeature(coords[k][l], extent);
                            }

                        }

                    } else { // line

                        for (let j = 0; j < coords.length; j++) {

                            this._createOlLinesFeature(coords[j], extent);

                        }

                    }

                } else {  // point

                    if (!isNaN(coords[0])) {

                        this._createOlLinesFeature(coords, extent);

                    }

                }

            }

        }

    }

    _createOlLinesFeature(pointArray, extent) {

        this._createOlLineFeature([[pointArray[0], extent[1]], [pointArray[0], extent[3]]]);
        this._createOlLineFeature([[extent[0], pointArray[1]], [extent[2], pointArray[1]]]);

    }

    _createOlLineFeature(lineArray, layer) {

        layer = layer || this._layers.lines;

        let feature = new ol.Feature();
        let line = new ol.geom.LineString(lineArray);
        feature.setGeometry(line);
        layer.getSource().addFeature(feature);

    }

    _createIntersectionPoints() {

        // aux lines and aux lines
        this._createIntersectionPointsFromLayers(this._layers.lines, this._layers.lines);

        // aux lines and drawing features
        this._createIntersectionPointsFromLayers(this._layers.lines, this._layers.drawing);

    }

    _createIntersectionPointsFromLayers(layer1, layer2) {

        let format = new ol.format.GeoJSON();

        let features1 = layer1.getSource().getFeatures();
        let features2 = layer2.getSource().getFeatures();

        let collection1 = format.writeFeaturesObject(features1);
        let collection2 = format.writeFeaturesObject(features2);

        let intersects = turf.lineIntersect(collection2, collection1);

        let intersectionFeatures = format.readFeaturesFromObject(intersects);
        this._layers.intersections.getSource().addFeatures(intersectionFeatures);

    }

    _createMidPoints() {

        let features = this._layers.drawing.getSource().getFeatures();
        let format = new ol.format.GeoJSON();

        for (let i = 0; i < features.length; i++) {

            let feature = features[i];
            let f = format.writeFeatureObject(feature);
            let segments = turf.lineSegment(f);

            for (let j = 0; j < segments.features.length; j++) {

                let centroid = turf.centroid(segments.features[j]);
                let centroidFeature = format.readFeatureFromObject(centroid);
                this._layers.midLines.getSource().addFeature(centroidFeature);

            }

        }

    }

    _createCentroids() {

        let features = this._layers.drawing.getSource().getFeatures();
        let format = new ol.format.GeoJSON();

        for (let i = 0; i < features.length; i++) {

            let feature = features[i];
            let f = format.writeFeatureObject(feature);
            let centroid = turf.centroid(f);
            let centroidFeature = format.readFeatureFromObject(centroid);
            this._layers.centroids.getSource().addFeature(centroidFeature);

        }

    }

    _createOrthoLines(feauture) {

        if (this._selectedFeaturesHover) {

            let extent = this.map.ol.getView().calculateExtent();

            let format = new ol.format.GeoJSON();
            let pointsCount = feauture.getGeometry().getCoordinates().length;

            let firstPoint = feauture.getGeometry().getCoordinates()[pointsCount - 2];

            let selectedFeatures = this._selectedFeaturesHover.getArray();

            for (let i = 0; i < selectedFeatures.length; i++) {

                if (selectedFeatures[i].getGeometry().getType() === "LineString") {

                    let selectedFeature = format.writeFeatureObject(selectedFeatures[i]);
                    let segments = turf.lineSegment(selectedFeature);

                    for (let j = 0; j < segments.features.length; j++) {

                        let segment = segments.features[j].geometry.coordinates;

                        if (this._pointOnLine(segment, firstPoint)) {

                            let az = this._getAzimuth(segment[0], segment[1]);
                            let dist = this._plainDistance([extent[0], extent[1]], [extent[2], extent[3]]);

                            for (let k = 1; k < 9; k++) {

                                let p1 = this._pointAzDist(firstPoint, (az + k * (Math.PI / 4)), dist);
                                this._createOlLineFeature([firstPoint, p1], this._layers.orthoLines);

                            }

                        }

                    }

                }

            }

        }

    }

    _pointAzDist(firstPoint, az, dist) {

        let x = firstPoint[0] + Math.sin(az) * dist;
        let y = firstPoint[1] + Math.cos(az) * dist;

        return [x, y];

    }

    _plainDistance(p1, p2) {

        return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));

    }

    _pointOnLine(segment, point) {

        let p1 = {
            x: segment[0][0],
            y: segment[0][1],
        };

        let p2 = {
            x: segment[1][0],
            y: segment[1][1],
        };

        let pa = {
            x: point[0],
            y: point[1]
        };

        return Math.abs(((p2.y - p1.y) / (p2.x - p1.x)) * (pa.x - p1.x) - (pa.y - p1.y)) < 10e-4;

    }

    _getAzimuth(p1, p2) {

        let azimuth = Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);
        azimuth = azimuth < 0 ? azimuth + (2 * Math.PI) : azimuth;

        return azimuth;


    }

    _addRing(ringFeature) {


        if (this._selectedFeaturesClick && this._selectedFeaturesClick.getArray().length > 0) {

            if (this._selectedFeaturesClick.getArray()[0].getGeometry().getType() == 'Polygon') {

                let polygon = turf.polygon(this._selectedFeaturesClick.getArray()[0].getGeometry().getCoordinates());
                let ring = turf.polygon(ringFeature.getGeometry().getCoordinates());

                let ringInPolygon = turf.booleanContains(polygon, ring);

                if (ringInPolygon) {

                    let intersection = turf.difference(polygon, ring);
                    let format = new ol.format.GeoJSON();
                    let intersectionFeatures = format.readFeaturesFromObject(intersection);

                    this._selectedFeaturesClick.getArray()[0].setGeometry(intersectionFeatures[0].getGeometry());

                } else {

                    alert('Ring não está contido no polígono!');

                }
            }


        } else {

            alert('Selecione uma feição!');

        }


    }

    _removeRing(ringInsidePoint) {


        if (this._selectedFeaturesClick && this._selectedFeaturesClick.getArray().length > 0) {

            if (this._selectedFeaturesClick.getArray()[0].getGeometry().getType() == 'Polygon') {

                let ringCount = this._selectedFeaturesClick.getArray()[0].getGeometry().getLinearRingCount();
                let newPolygon = new ol.geom.Polygon([this._selectedFeaturesClick.getArray()[0].getGeometry().getLinearRing(0).getCoordinates()]);


                if (ringCount > 1) {

                    for (let i = 1; i < ringCount; i++) {

                        let lRing = this._selectedFeaturesClick.getArray()[0].getGeometry().getLinearRing(i);
                        let polygon = turf.polygon([lRing.getCoordinates()]);
                        let point = turf.point(ringInsidePoint.getGeometry().getCoordinates());

                        let pointInRing = turf.booleanContains(polygon, point);

                        if (!pointInRing) {
                            newPolygon.appendLinearRing(lRing);
                        }

                    }

                }

                this._selectedFeaturesClick.getArray()[0].setGeometry(newPolygon);

            }

        } else {

            alert('Selecione uma feição!');

        }


    }

    _buffer(radius) {

        if (this._selectedFeaturesClick && this._selectedFeaturesClick.getArray().length > 0) {

            let selectedFeatures = this._selectedFeaturesClick.getArray();
            for (let i = 0; i < selectedFeatures.length; i++) {

                let format = new ol.format.GeoJSON();

                selectedFeatures[i].getGeometry().transform(this.map.ol.getView().getProjection().getCode(), 'EPSG:4326');
                let feature = format.writeGeometryObject(selectedFeatures[i].getGeometry());
                let buffered = turf.buffer(feature, radius, { units: 'meters' });


                let intersectionFeatures = format.readFeatureFromObject(buffered);
                intersectionFeatures.getGeometry().transform('EPSG:4326', this.map.ol.getView().getProjection().getCode());
                this._layers.drawing.getSource().addFeature(intersectionFeatures);

            }

        }

    }

    _toggleDisplayBox(boxName) {

        let elm = document.getElementById(`${boxName}_container_${this.id}`);

        if (elm.style.display === 'none' || elm.style.display === '') {
            elm.style.display = 'block';
        } else {
            elm.style.display = 'none';
        }

    }

    _clearPrompt() {

        this._promptFunction = null;
        document.getElementById(`prompt_container_${this.id}`).innerHTML = '';
        document.getElementById(`prompt_container_${this.id}`).style.display = 'none';
        document.getElementById(`prompt_${this.id}`).value = '';

    }

    _pointPrompt(coord) {

        if (!coord) {

            this._promptTip = 'Insira um par de coordenadas: x y';

        } else {

            this._fromWktPrompt('Point(' + coord + ')');

        }

    }


    _fromWktPrompt(wkt) {

        if (!wkt) {

            this._promptTip = 'Insira um WKT: POINT(x y), LINESTRING(x y, x y), ...';

        } else {

            let format = new ol.format.WKT();

            let feature = format.readFeature(wkt, {
                dataProjection: 'EPSG:4326',
                featureProjection: this.map.ol.getView().getProjection().getCode()
            });

            this._layers.drawing.getSource().addFeature(feature);

            this._clearPrompt();

        }



    }

    _bufferPrompt(radius) {

        if (!this._selectedFeaturesClick || this._selectedFeaturesClick.getLength() === 0) {

            this._promptTip = 'ERRO: Selecione uma feição para proseguir.';
            return;

        }

        if (!radius) {

            this._promptTip = 'Insira o raio do buffer (m):';

        } else {

            this._buffer(radius);
            this._clearPrompt();

        }

    }

    _selectPrompt() {

        this._selectClickControl.getFeatures().clear();
        this._selectClickControl.setActive(!this._selectClickControl.getActive());
        this._uiHandler();
        this._clearPrompt();

    }

    _promptHandler(value) {

        if (this._promptFunction) {
            this._promptFunction(value);
            return;
        }


        for (let i = 0; i < this.cmdList.length; i++) {

            for (let i = 0; i < this.cmdList.length; i++) {

                if (this.cmdList[i].cmd === value.toLowerCase()) {

                    this._promptFunction = this.cmdList[i].fnc;
                    this._promptFunction();
                    document.getElementById(`prompt_${this.id}`).value = '';
                    this._promptTipHandler();

                }

            }

        }

    }

    _promptTipHandler(value) {

        let matches = 0;
        let elm = document.getElementById(`prompt_container_${this.id}`);
        elm.innerHTML = '';

        if (value && !this._promptFunction) {

            for (let i = 0; i < this.cmdList.length; i++) {

                if (value != '' && this.cmdList[i].cmd.indexOf(value.toLowerCase()) != -1) {

                    elm.innerHTML += `${this.cmdList[i].cmd.toUpperCase()}<br>`;
                    matches++;

                }

            }

            if (matches == 0) {

                elm.style.display = 'none';

            } else {

                elm.style.display = 'block';

            }

        } else {

            if (this._promptTip) {

                elm.innerHTML = this._promptTip;

            } else {

                elm.style.display = 'none';

            }

        }

    }

    _registerEvents() {

        this._layers.drawing.getSource().on('addfeature', () => this._createAuxFeatures());
        this.map.ol.getView().on('change', () => this._createAuxFeatures());

        // Point
        document.getElementById(`addPoint_${this.id}`).addEventListener('click', () => {

            this.map.toolbox.draw.getPoint().then((point) => {

                this._layers.drawing.getSource().addFeature(point);

            });

        });

        // Line
        document.getElementById(`addLine_${this.id}`).addEventListener('click', () => {

            let drawstart, drawend;
            [drawstart, drawend] = this.map.toolbox.getLineString();

            drawend.then((line) => {

                this._isDrawing = false;
                this._layers.drawing.getSource().addFeature(line);

            });

            drawstart.then((line) => {

                line.getGeometry().on('change', () => {

                    this._isDrawing = true;
                    if (this._toolsStatus.auxLines) this._createOrthoLines(line);

                });

            });

        });

        // Polygon
        document.getElementById(`addPolygon_${this.id}`).addEventListener('click', () => {

            this.map.toolbox.draw.getPolygon().then((polygon) => {

                this._layers.drawing.getSource().addFeature(polygon);

            });

        });

        // Ring
        document.getElementById(`addRing_${this.id}`).addEventListener('click', () => {

            this.map.toolbox.draw.getPolygon().then((polygon) => {

                this._addRing(polygon);

            });

        });

        // Ring
        document.getElementById(`removeRing_${this.id}`).addEventListener('click', () => {

            this.map.toolbox.draw.getPoint().then((point) => {

                this._removeRing(point);

            });

        });

        // Buffer
        document.getElementById(`addBuffer_${this.id}`).addEventListener('click', () => {

            let radius = prompt("Raio:", 500)
            this._buffer(radius);

        });

        document.getElementById(`prompt_${this.id}`).addEventListener('keyup', (e) => {

            var key = e.which || e.keyCode;
            if (key == 13) {

                this._promptHandler(e.target.value);

            } else if (key === 'Escape' || key === 27) {

                this._clearPrompt();

            } else {

                this._promptTipHandler(e.target.value);

            }

        });

        document.getElementById(`auxLines_${this.id}`).addEventListener('click', () => {

            this._toolsStatus.auxLines = !this._toolsStatus.auxLines;
            this._refreshStatus();

        });


        document.getElementById(`intersections_${this.id}`).addEventListener('click', () => {

            this._toolsStatus.intersections = !this._toolsStatus.intersections;
            this._refreshStatus();

        });


        document.getElementById(`centroids_${this.id}`).addEventListener('click', () => {

            this._toolsStatus.centroids = !this._toolsStatus.centroids;
            this._refreshStatus();

        });

        document.getElementById(`midPoints_${this.id}`).addEventListener('click', () => {

            this._toolsStatus.midPoints = !this._toolsStatus.midPoints;
            this._refreshStatus();

        });

        document.getElementById(`ortho_${this.id}`).addEventListener('click', () => {

            this._toolsStatus.ortho = !this._toolsStatus.ortho;
            this._refreshStatus();

        });

        document.getElementById(`select_${this.id}`).addEventListener('click', () => {

            this._selectPrompt();

        });

        document.getElementById(`snap_${this.id}`).addEventListener('click', () => {

            let snapContainer = document.getElementById(`snap-container_${this.id}`);


            if (snapContainer.style.display === 'none' || snapContainer.style.display === '') {
                snapContainer.style.display = 'block';
            } else {
                snapContainer.style.display = 'none';
            }

        });

    }

}

export { Cad };