import { GeoWidget } from '../core/GeoWidget';
import arrow from '../assets/arrow';

class Draft extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Rascunho';
        config.title = config.title || 'Rascunho';
        config.class = config.class || 'gb-draft';
        config.geometryType = config.geometryType || 'linestring';
        super(config);

        this._textBtnElement = null;
        this._pointBtnElement = null;
        this._lineBtnElement = null;
        this._polygonBtnElement = null;
        this._arrowBtnElement = null;
        this._imageBtnElement = null;
        this._tipElement = null;
        this._textContainer = null;
        this._textTest = null;

        this._tipMsg = '';
        this._initialMsg = 'Selecione uma ferramenta';

        this._layer = null;
        this._overlays = [];
        this._pointStyle = null;
        this._lineStyle = null;
        this._polygontStyle = null;

        this.ui = this.builUi();

    }

    initialize() {

        this.on('ready', () => {

            this._registerEvents();

        });

     }

    _initLayer() {

        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#685ff1',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#685ff1'
                    })
                })
            })
        });

        this._layer.setZIndex(999);
        this.map.ol.addLayer(this._layer);

    }

    _initSefaultStyles() {

        this._pointStyle = new ol.style.Style({
            image: new ol.style.Circle({
                radius: 5,
                fill: new ol.style.Fill({
                    color: 'rgba(255,255,255,0.5)',
                }),
                stroke: new ol.style.Stroke({
                    color: '#F0F',
                    width: 2
                })
            })
        });

        this._lineStyle = new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(0, 255, 255, 0.5)'
            }),
            stroke: new ol.style.Stroke({
                color: 'cyan',
                width: 1.5
            })
        });

        this._polygonStyle = new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(255,255,255,0.5)',
            }),
            stroke: new ol.style.Stroke({
                color: '#F0F',
                width: 2
            })
        });



    }


    builUi() {

        let draftElement = document.createElement('div');
        draftElement.className = 'gb-measure-content';

        let btnsContainer = document.createElement('div');
        btnsContainer.style.display = 'flex';

        this._tipMsg = this._initialMsg;
        this._textContainer = document.createElement('div');
        this._textContainer.className = 'gb-measure-tip';
        this._textContainer.innerHTML = this._tipMsg;

        this._textBtnElement = document.createElement('div');
        this._textBtnElement.className = 'gb-measure-btn gb-draft-text';

        this._pointBtnElement = document.createElement('div');
        this._pointBtnElement.className = 'gb-measure-btn gb-measure-point';

        this._lineBtnElement = document.createElement('div');
        this._lineBtnElement.className = 'gb-measure-btn gb-draft-line';

        this._polygonBtnElement = document.createElement('div');
        this._polygonBtnElement.className = 'gb-measure-btn gb-measure-polygon';

        this._arrowBtnElement = document.createElement('div');
        this._arrowBtnElement.className = 'gb-measure-btn gb-draft-arrow';

        this._imageBtnElement = document.createElement('div');
        this._imageBtnElement.className = 'gb-measure-btn gb-draft-image';

        this._clearBtnElement = document.createElement('div');
        this._clearBtnElement.className = 'gb-measure-btn gb-measure-clear';

        btnsContainer.appendChild(this._textBtnElement);
        btnsContainer.appendChild(this._pointBtnElement);
        btnsContainer.appendChild(this._lineBtnElement);
        btnsContainer.appendChild(this._polygonBtnElement);
        btnsContainer.appendChild(this._arrowBtnElement);
        btnsContainer.appendChild(this._imageBtnElement);
        btnsContainer.appendChild(this._clearBtnElement);
        draftElement.appendChild(btnsContainer);
        
        draftElement.appendChild(this._textContainer);
        //draftElement.appendChild(this._clearBtnElement);

        return draftElement;

    }

    _makeDraggable(element) {

        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        // otherwise, move the DIV from anywhere inside the DIV: 
        element.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            element.style.top = (element.offsetTop - pos2) + 'px';
            element.style.left = (element.offsetLeft - pos1) + 'px';
            element.style.opacity = 0.5;
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
            element.style.opacity = 1;
        }
    }

    _toBase64 (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    _setOverlays(coords) {

        let pointLabel = document.createElement('div');
        let inputText = document.createElement('input');
        pointLabel.className = 'gb-desc-point';
        pointLabel.innerHTML = 'TEXT';
       this._makeDraggable(pointLabel);

        let label = new ol.Overlay({
            element: pointLabel,
            offset: [4, 4],
            positioning: 'top-left'
        });

        label.setPosition(coords.getCoordinates());
        this.map.ol.addOverlay(label);

        pointLabel.addEventListener('dblclick', () => {
            let textTemp = pointLabel.innerHTML;
            inputText.value = textTemp;
            pointLabel.innerHTML = '';
            pointLabel.appendChild(inputText);
            inputText.focus();
        });

        pointLabel.addEventListener('keydown', e => {
            if (e.which == 13) {
                pointLabel.innerHTML = pointLabel.firstElementChild.value;
            }
        });

        return label;
  }

    _registerEvents() {

        // Click
        this._textBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para adicionar um texto';
            this.map.toolbox.draw.getPoint().then((point) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._overlays.push(this._setOverlays(point.getGeometry()));
                //this._layer.getSource().addFeature(point);

            });

        });

        this._pointBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para obter um ponto';
            this.map.toolbox.draw.getPoint().then((point) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._layer.getSource().addFeature(point);

            });

        });

        this._lineBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para desenhar uma linha';
            this.map.toolbox.draw.getPolyline().then((line) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._layer.getSource().addFeature(line);

            });

        });

        this._polygonBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para obter um polígono';
            this.map.toolbox.draw.getPolygon().then((polygon) => {

                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._layer.getSource().addFeature(polygon);

            });

        });

        this._arrowBtnElement.addEventListener('click', () => {

            this._tipMsg = 'Clique no mapa para desenhar uma direção';
            this.map.toolbox.draw.getArrow().then((pline) => {

                let myStyles = [];

                let plineTemp = pline.clone()

                pline.getGeometry().forEachSegment(function(start, end) {
                    let dx = end[0] - start[0];
                    let dy = end[1] - start[1];
                    let rotation = Math.atan2(dy, dx);
                    // arrows
                    myStyles.push(new ol.style.Style({
                        geometry: new ol.geom.Point(end),
                        image: new ol.style.Icon({
                            src: arrow.base64,
                            anchor: [0.75, 0.5],
                            rotateWithView: true,
                            rotation: -rotation
                        }),
                        stroke: new ol.style.Stroke({
                            color: '#685ff1',
                            width: 150
                        }),
                    }));
                });

                pline.setStyle(myStyles);
                this._tipMsg = this._initialMsg;
                this._textContainer.innerHTML = this._tipMsg;
                this._layer.getSource().addFeature(pline);
                this._layer.getSource().addFeature(plineTemp);

            });

        });

        this._imageBtnElement.addEventListener('click', () => {

            let input = document.createElement('input');
            input.type = 'file';
            input.setAttribute('accept', '.jpg, .png, .jpeg, .gif |image/*');
            let imageFile = null;

            input.onchange = e => { 
                imageFile = e.target.files[0];

                if (imageFile) {

                    this._toBase64(imageFile).then((image) => {

                        this._tipMsg = 'Clique no mapa para adicionar um texto';
                        this.map.toolbox.draw.getPoint().then((point) => {
                            
                            let myStyle = new ol.style.Style({
                                geometry: point.getGeometry(),
                                image: new ol.style.Icon({
                                    src: image,
                                    anchor: [0.5, 0.5]
                                })
                            });

                            point.setStyle(myStyle);
                            this._tipMsg = this._initialMsg;
                            this._textContainer.innerHTML = this._tipMsg;
                            this._layer.getSource().addFeature(point);
                        });

                    });
                }
            }

            input.click();
        });

        this._clearBtnElement.addEventListener('click', () => {

            this._clearOverlays();
            this._clearGeoms();

        });


        // MouseOver
        this._textBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Incluir texto';
            this._textContainer.style.display = 'block';

        });

        this._pointBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Incluir ponto';
            this._textContainer.style.display = 'block';

        });

        this._lineBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Incluir linha';
            this._textContainer.style.display = 'block';

        });

        this._polygonBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Incluir polígono';
            this._textContainer.style.display = 'block';

        });

        this._arrowBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Incluir seta';
            this._textContainer.style.display = 'block';

        });

        this._imageBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Incluir imagem';
            this._textContainer.style.display = 'block';

        });

        this._clearBtnElement.addEventListener('mouseover', () => {

            this._textContainer.innerHTML = 'Limpar rascunho';
            this._textContainer.style.display = 'block';

        });

        // MouseOut
        this._textBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._pointBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._lineBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._polygonBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._arrowBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._imageBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

        this._clearBtnElement.addEventListener('mouseout', () => {

            this._textContainer.innerHTML = this._tipMsg;

        });

    }

    _clearOverlays(){

        this.map.ol.getOverlays().clear();

    }

    _clearGeoms(){

        this._layer.getSource().clear();

    }

    activate() {

        this._initSefaultStyles();
        this._initLayer();
        this.show();

    }

    deactivate() {

        this._clearOverlays();
        this._clearGeoms();
        this.hide();
    }

}

export { Draft };