import { GeoWidget } from '../core/GeoWidget';

class GetMapInfo extends GeoWidget {

	constructor(config) {

		config = config || {};
		config.title = 'Obter Informações';
		config.tip = config.tip || 'Obter Informações';
		config.maxWidth = '800px';
		config.maxHeight = '800px';
		config.minWidth = '600px';
		config.minHeight = '350px';
		config.class = config.class || 'ls-submenu-infoMap gb-control';
		config.geowiseApiConfig = config.geowiseApiConfig || {
			url: '',
			headers: {},
		}

		super(config);

		this.ui = this._getTemplate();
		this._box = null;
		this._currentProjection = null;

		this.on('ready', () => {
			this._buildElements();
			this._registerEvents();

		});


	}

	initialize() {

		this._srsList = this.map._config.srs;
		this._getPropsTypes();


	};

	_getTemplate() {

		let container = document.createElement('div');
		container.style.width = '100%';


		container.innerHTML = `
			<div id="info-box-${this.id}" class="card" style="min-width: 600px; width: 100%; height: 100%; z-index: 9999; background: #fff; font-family: 'Nunito', sans-serif !important; font-weight: 400 !important; font-size: 0.9rem">

			<div class="card-header position-absolute w-100" style="background: #fff;">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<a id="coord-btn-${this.id}" class="nav-link" href="#">Coordenadas</a>
					</li>
					<li class="nav-item">
						<a id="info-btn-${this.id}" class="nav-link" href="#">Informações</a>
					</li>
				</ul>
			</div>
			<div id="info-body-${this.id}" class="card-body" style="background: #fff; margin-top: 60px;">

			</div>
			<div class="card-footer text-muted">
				<div class="row p-0 m-0">
					<div class="col">
						<a id="info-external-${this.id}" rel="noopener noreferrer" target="_blank" href="https://www.google.com/maps/search/-25.4725331,-49.2100546">Ver no Google Maps</a>
					</div>
				</div>
			</div>
		</div>
		`;

		return container;

	}

	_getPropsTypes() {
		let xhrProp = new XMLHttpRequest();
		// xhrProp.open('GET', '/geowise/mapa/all_properties');
		xhrProp.open('GET', this.config.geowiseApiConfig.url + '/geowise/mapa/all_properties');
		for (let key in this.config.geowiseApiConfig.headers) {
			xhrProp.setRequestHeader(key, this.config.geowiseApiConfig.headers[key]);
		}
		xhrProp.onload = () => {
			if (xhrProp.status == 200) {
				let data = JSON.parse(xhrProp.responseText);
				this.properties = data;
			}
		}
		xhrProp.send();
	}

	_buildElements() {

		this._box = document.getElementById("info-box-" + this.id);
		this._infoBtn = document.getElementById("info-btn-" + this.id);
		this._coordBtn = document.getElementById("coord-btn-" + this.id);
		this._body = document.getElementById("info-body-" + this.id);
		this._externalLink = document.getElementById("info-external-" + this.id);
		// this._btnElm = document.getElementById("gb-panel-lt_map_content_control_" + this.id);
		 this._btnElm = document.querySelector(".ls-submenu-infoMap.gb-control[id$='" + this.id + "']");

	};

	_writeCoords() {

		this._infoBtn.classList.remove('active');
		this._coordBtn.classList.add('active');

		var container = document.createElement('div');
		container.className = 'row p-0 m-0';
		this._body.innerHTML = '';


		for (var i = 0; i < this._srsList.length; i++) {

			var srs = this._srsList[i];
			this._currentProjection = srs;
			var projectedCoord = this._projectPoint(this._currentCoord);
			var formatedCoord = this._formatCoord(projectedCoord);

			let d1 = document.createElement('div');
			d1.className = 'col-6 p-0 m-0';
			container.appendChild(d1)

			let small1 = document.createElement('small');
			small1.className = 'font-weight-bold';
			small1.innerHTML = srs.name;
			d1.appendChild(small1);

			let row = document.createElement('div');
			row.className = 'row';
			d1.appendChild(row);

			let col1 = document.createElement('div');
			col1.className = 'col-9 pr-0 mr-0';
			row.appendChild(col1);

			let input = document.createElement('input');
			input.className = 'form-control form-control-sm';
			input.value = formatedCoord;
			input.style.fontSize = '12px';
			input.style.border = 'none';
			input.type = 'text';
			col1.appendChild(input);

			let col2 = document.createElement('div');
			col2.className = 'col-3 pl-0 ml-0';
			row.appendChild(col2);

			let small2 = document.createElement('small');
			col2.appendChild(small2);

			let a = document.createElement('a');
			a.className = 'alert-link';
			a.href = '#';
			a.innerText = 'Copiar';
			a.addEventListener('click', function () {
				input.select();
				if (document.execCommand('copy')) {
					new Notify({ message: `Coordenada copiada para a área de transferência.`, type: 'success', timeout: appConfig.timeoutMsg }).show();
				} else {
					new Notify({ message: `Não foi possível copiar. Utilize um navegador compatível.`, type: 'warning', timeout: appConfig.timeoutMsg }).show();
				}
				window.getSelection().removeAllRanges();
			});
			col2.appendChild(a);

		}

		this._body.appendChild(container);
		var coord = ol.proj.transform(this._currentCoord, this.map.ol.getView().getProjection().getCode(), 'EPSG:4326');
		this._externalLink.href = 'https://www.google.com/maps/search/' + coord[1] + ',' + coord[0];

	};

	_writeInfo() {

		this.map.clearDrawingGeometries();

		this._infoBtn.classList.add('active');
		this._coordBtn.classList.remove('active');


		this._body.innerHTML = '';

		var container = document.createElement('div');
		container.className = 'p-0 m-0 h-100';
		this._infoContent = container;

		this._body.appendChild(container);
		this._getInfos(this._currentCoord);

	};

	_projectPoint(coordinate) {

		var wgsCoords = ol.proj.transform(coordinate, this.map.ol.getView().getProjection().getCode(), 'EPSG:4326');
		return proj4(this._currentProjection.defs, wgsCoords);

	};

	_formatCoord(coordinate) {

		var displayCoords = '';

		if (this._currentProjection.format === 'DMS') {

			displayCoords = ol.coordinate.toStringHDMS(coordinate, this.map.config.precision || 3).replaceAll('.', ',');


		} else if (this._currentProjection.format === 'DD') {

			displayCoords = coordinate.map((e) => {
				return Number(e.toFixed(8));

			});
			displayCoords = ((displayCoords.reverse().toString()).replace(',', ';')).replaceAll('.', ',')


		} else {

			displayCoords = coordinate.map((e) => {


				return Number(e.toFixed(this.map.config.precision || 3))
			});

			displayCoords = ((displayCoords.toString()).replace(',', ';')).replaceAll('.', ',')



		}

		return displayCoords;

	}

	_getGeometryName(conf) {

		return new Promise((resolve, reject) => {

			let url = conf.source +
				'/wfs?service=wfs&version=1.0.0&request=GetFeature&' +
				'typeNames=' + conf.workspace + ':' + conf.layer + '&' +
				'maxFeatures=1&' +
				'outputFormat=application/json&' +
				'exceptions=application/json';

			let xhr = new XMLHttpRequest();
			xhr.open('GET', url);
			if (conf.auth) {
				xhr.setRequestHeader('Authorization', conf.auth);
			}
			xhr.onload = () => {
				if (xhr.status == 200) {

					let data = JSON.parse(xhr.responseText);
					if (data.numberReturned > 0) {
						resolve(data.features[0].geometry_name);
					} else {
						reject("[GetMapInfo] Não foi possível obter dados da geometria.");
					}

				} else {
					reject("[GetMapInfo] Erro ao buscar dados do WFS.");
				}
			}
			xhr.onerror = function () {
				reject("[GetMapInfo] Erro ao buscar dados do WFS.");
			}
			xhr.send();

		})

	}

	_getFeatureData(conf) {

		const fullCode = this.map.ol.getView().getProjection().getCode();
		const unit = this.map.ol.getView().getProjection().getUnits();
		const d = unit == 'm' ? 1 : 0.00001;
		const c = conf.coords;
		const wkt = `SRID=${fullCode.replace('EPSG:', '')};POLYGON((${c[0] - d} ${c[1] - d}, ${c[0] + d} ${c[1] - d}, ${c[0] + d} ${c[1] + d}, ${c[0] - d} ${c[1] + d}, ${c[0] - d} ${c[1] - d}))`;

		return new Promise((resolve, reject) => {

			this._getGeometryName(conf).then(geometryName => {
				let url = conf.source +
					'/wfs?service=wfs&version=2.0.0&request=GetFeature&' +
					'typeNames=' + conf.workspace + ':' + conf.layer + '&' +
					'srsName=' + fullCode + '&' +
					'outputFormat=application/json&' +
					'exceptions=application/json&' +
					'CQL_FILTER=INTERSECTS(' + geometryName + ', ' + wkt + ')';

				let xhr = new XMLHttpRequest();
				xhr.open('GET', url);
				if (conf.auth) {
					xhr.setRequestHeader('Authorization', conf.auth);
				}
				xhr.onload = () => {
					if (xhr.status == 200) {
						resolve(JSON.parse(xhr.responseText));
					} else {
						reject("[GetMapInfo] Erro ao buscar dados do WFS.");
					}
				}
				xhr.onerror = function () {
					reject("[GetMapInfo] Erro ao buscar dados do WFS.");
				}
				xhr.send();

			})
			.catch((e) => {
				console.log(e)
			})

		});

	}

	_getLayerInfo(conf) {

		let container = document.createElement('div');
		container.className = 'row p-0 m-0';

		let layerContent = document.createElement('div');
		layerContent.className = 'col-8 p-0 m-0';

		let layerName = document.createElement('div');
		layerName.className = 'col-4 p-0 m-0';
		layerName.innerHTML = `${conf.groupName}\n${conf.layerName}`;

		container.append(layerName);
		container.append(layerContent);

		this._infoContent.append(container);

		this._getFeatureData(conf).then(data => {

			let attributes = '';

			if (data.features.length == 0) {

				layerContent.innerHTML = `Nenhuma feição encontrada. <br><hr>`;
			}

			for (let i = 0; i < data.features.length; i++) {

				this.map.drawGeometry(data.features[i].geometry, 'geojson', {
					style: new ol.style.Style({
						fill: new ol.style.Fill({
							color: 'rgba(255, 255, 0, 0.1)'
						}),
						stroke: new ol.style.Stroke({
							color: '#ff0',
							width: 4
						}),
						image: new ol.style.Circle({
							radius: 7,
							fill: new ol.style.Fill({
								color: '#ff0'
							})
						})
					})
				});

				let keys = Object.keys(data.features[0].properties);
				let props = data.features[0].properties;

				for (let j = 0; j < keys.length; j++) {

					if (keys[j] == 'fields') {

						let fields = props[keys[j]];
						let nKeys = Object.keys(fields);

						for (let k = 0; k < nKeys.length; k++) {
							attributes += `
							<p>
								<strong>${this._getNameKey(nKeys[k])}: </strong>
								${this._getNameType(conf.layer, nKeys[k], fields[nKeys[k]])}
							</p>`;
						}

					} else {

						attributes += `
						<p>
							<strong>${this._getNameKey(keys[j])}: </strong>
							${this._getNameType(conf.layer, keys[j], props[keys[j]])}
						</p>`;

					}

				}

				attributes += `<hr>`;
				layerContent.innerHTML = attributes;

			}

		})
		.catch((e) => {
			console.log(e)
		})

	}


	_getNameKey(key) {

		if (this.properties) {

			for (let i = 0; i < this.properties.length; i++) {
				const prop = this.properties[i];

				if (key == prop.column) {
					return prop.name;
				}

			}

		}


		return key;

	}

	_getNameType(layer, key, type) {

		return type;

	}

	_getInfos(coords) {

		const content = this.map.content;

		for (let i = 0; i < content.length; i++) {

			const group = content[i];

			if ((group.type.toLowerCase() == 'wms' ||
				group.type.toLowerCase() == 'wfs' ||
				group.type.toLowerCase() == 'ogc') &&
				group.display) {

				for (let j = 0; j < group.layers.length; j++) {

					const layer = group.layers[j];
					if (layer.display) {

						this._getLayerInfo({
							source: group.source,
							workspace: group.workspace,
							layer: layer.layer,
							auth: group.auth,
							coords: coords,
							groupName: group.name,
							layerName: layer.name,
						})
					}

				}

			}

		}

	}

	_registerEvents() {


		this._layer = new ol.layer.Vector({
			source: new ol.source.Vector(),
			style: [
				new ol.style.Style({
					image: new ol.style.Circle({
						radius: 9,
						fill: new ol.style.Fill({
							color: '#0ff'
						})
					})
				}),
				new ol.style.Style({
					image: new ol.style.Circle({
						radius: 14,
						stroke: new ol.style.Stroke({
							color: '#0ff',
							width: 2
						}),
					})
				}),

			]
		});

		this._layer.setZIndex(999);
		this.map.ol.addLayer(this._layer);

		this.map.ol.getViewport().addEventListener('contextmenu', (evt) => {

			evt.preventDefault();
			this.map.clearDrawingGeometries();
			this._layer.getSource().clear();
			this._currentCoord = this.map.ol.getEventCoordinate(evt);
			this._writeCoords();
			this.show();
			let pt = new ol.geom.Point(this.map.ol.getEventCoordinate(evt));
			let ft = new ol.Feature(pt);
			this._layer.getSource().addFeature(ft);
			this.moveTo(...this.map.ol.getPixelFromCoordinate(this._currentCoord));

		});


		this.map.ol.getViewport().addEventListener('click', (evt) => {


			if (this._btnElm.classList.value == 'ls-submenu-infoMap gb-control gb-control-active') {

				evt.preventDefault();
				this.map.clearDrawingGeometries();
				this._layer.getSource().clear();
				this._currentCoord = this.map.ol.getEventCoordinate(evt);
				this._writeCoords();
				this._writeInfo();
				this.show();
				let pt = new ol.geom.Point(this.map.ol.getEventCoordinate(evt));
				let ft = new ol.Feature(pt);
				this._layer.getSource().addFeature(ft);
				this.moveTo(...this.map.ol.getPixelFromCoordinate(this._currentCoord));



			}


		});

		this.map.ol.on('moveend', () => {
			//	this.moveTo(...this.map.ol.getPixelFromCoordinate(this._currentCoord));

		});

		document.onkeydown = (evt) => {

			evt = evt || window.event;
			if (evt.keyCode == 27) {
				this._hideBox();
			}

		};

		this._infoBtn.addEventListener('click', () => {

			this._writeInfo();

		});

		this._coordBtn.addEventListener('click', () => {

			this.map.clearDrawingGeometries();
			this._writeCoords();

		});

	}
	activate() {


	}

	deactivate() {

		this._layer.getSource().clear();
		this.map.clearDrawingGeometries();
		this.hide();

	}

}
export {GetMapInfo}; 