import { GeoWidget } from '../core/GeoWidget';

class SearchTool extends GeoWidget {
  constructor(config) {
    config = config || {};
    config.tip = config.tip || 'Ferramenta de Busca';
    config.title = config.title || 'Ferramenta de Busca';
    config.class = config.class || 'gb-search-control';
    config.domain = config.domain || '';
    config.docked = config.docked || false;
    config.minWidth = config.minWidth || '300';
    config.minHeight = config.minHeight || '400';
    config.maxWidth = config.maxWidth || '400';
    config.maxHeight = config.maxHeight || '600';
    config.userLogin = config.userLogin || '';
    config.userAuthToken = config.userAuthToken || '';
    config.map = config.map || '';


    super(config);
    this.ui = null;
    this._onSearch = config.onSearch;
    this._searchFieldId = config.searchFieldId || 'search-field';
    this._searchFieldElm = null;
    this._searchButtonElm = null;
    this._errorMsgElm = null;
    this.mapa = config.map;
    this.domain = config.domain;
    this.userLogin = config.userLogin;
    this.userAuthToken = config.userAuthToken;
  }

  initialize() {
    this.ui = this.builUi();
    this._getElements()
    this._overlay = new ol.Overlay({
      position: [0, 0],
      positioning: 'left-top',
      element: document.createElement('div'),
      stopEvent: false,
      autoPan: true,
      autoPanMargin: 100
    });
    this.map.ol.addOverlay(this._overlay);
  }

  builUi() {
    return `
      <div class="p-3 d-flex justify-between align-items-center" style="width: 500px; height: auto">
        <div class="input-group">
          <div class="w-100">
            <input type="text" id=${this._searchFieldId} class="w-100 form-control search-tool" placeholder="Digite o endereço ou inscrição do imóvel" style="height: 38px; width: 400px; border-radius: 10px!important">
          </div>
        </div>
      </div>

      <div class="px-3 mb-3 text-danger" style="width: 500px">
        <span id="error-msg"></span>
      </div>

      <div class="px-3 mb-3 text-muted" style="width: 500px">
        <span id="info-msg"></span>
      </div>

      <div class="px-3 mb-3" id="search-results" style="width: 500px; overflow: auto; height: 200px;">
      </div>
    `
  }

  __createWidget() {
    this.__elements.widget = document.createElement('div');
    this.__elements.widget.id = `gb_c${this.id}_${this.map.target}`;
    this.__elements.widget.className = 'gb gb-widget';
    this.__elements.widget.style.height = 'auto';
    this.__elements.widget.style.maxHeight = 'auto';
  }

  _getElements() {
    this._searchFieldElm = document.getElementById(this._searchFieldId);
    this._searchButtonElm = document.getElementById("search-button");
    this._errorMsgElm = document.getElementById("error-msg");
  }

  _search() {
    this.onSearch();
  }

  _submit() {
    if (this._searchFieldElm.value != "") {
      this._search();
    } else {
      this._errorMsgElm.innerHTML = "Escreva um endereço ou inscrição."
    }
  }

  _registerEvents() {
    this._searchFieldElm.addEventListener('keyup', () => {
      if (this._searchFieldElm.value.length >= 4) {

        let inscricao = /^[0-9.]+$/.test(document.getElementById('search-field').value)
        if (inscricao) {
          this.getAddressDoors(document.getElementById('search-field').value)
        } else {
          this._submit();
        }
      }
    })
  }

  _projectPoint(coordinate) {
    let gd = '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs';
    let utm = '+proj=utm +zone=22 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';

    return proj4(gd, utm, coordinate);
  };

  onSearchResultSelected(geometry, coordinates, selected_result_index) {
    const results = document.getElementById('search-results');
    let resultsToRemove = results.getElementsByClassName('gb-search-tool-result');
    let resultToKeep = document.getElementById(`search-result-${selected_result_index}`);

    Array.from(resultsToRemove).forEach(function (element) {
      if (element !== resultToKeep) {
        element.parentNode.removeChild(element);
      }
    });

    this.mapa.drawGeometry(geometry, 'wkt', { srid: 'EPSG:4326', fitToGeometry: true });

  }

  getAddressDoors(address) {
    const errorEl = document.getElementById('error-msg');
    const infoEl = document.getElementById('info-msg');
    let xhr = new XMLHttpRequest();
    let url = `${this.domain}/cadastro/autonomas/get_autonomas_by_address`

    xhr.open('POST', url);
    xhr.setRequestHeader('X-User-Token', this.userAuthToken);
    xhr.setRequestHeader('X-User-Login', this.userLogin);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');

    let body = JSON.stringify({
      search: address
    });

    xhr.onload = () => {
      if (xhr.status == 200) {
        let data = JSON.parse(xhr.responseText);
        let subContent = document.getElementById('search-results');
        if (data.length == 0) {
          subContent.innerHTML = '';
          infoEl.innerHTML = 'O critério de busca informado, não foi encontrado.';
        } else {
          infoEl.innerHTML = 'Foram encontradas as seguintes Autonomas: (' + data.length + ')';
          errorEl.innerHTML = '';
          subContent.innerHTML = '';

          data.map((data, index) => {

            let resultEl = document.createElement('div');
            resultEl.id = `search-result-${index}`
            resultEl.classList.add('w-100', 'mb-1', 'py-2', 'border-top', 'gb-search-tool-result');

            let addressNameEl = document.createElement('span');
            addressNameEl.classList.add('text-break', 'fs-6', 'text-capitalize');
            addressNameEl.innerHTML = data.fields.endereco_completo

            resultEl.appendChild(addressNameEl);
            resultEl.addEventListener('click', this.onSearchResultSelected.bind(this, data.wgs84_geom, data.centroid, index));

            subContent.appendChild(resultEl);
          });

        }
      }
    }

    xhr.onerror = () => {
      errorEl.innerHTML = 'Erro na integração. Recarregue a página e tente novamente.'
      document.body.style.cursor = 'default';
      this.mapa.mapElement.style.cursor = 'default';
    }

    xhr.send(body);
  }

  onSearch() {
    document.body.style.cursor = 'progress';
    this.mapa.mapElement.style.cursor = 'progress';

    const errorEl = document.getElementById('error-msg');
    const infoEl = document.getElementById('info-msg');
    let xhr = new XMLHttpRequest();

    let url = `${this.domain}/cadastro/autonomas/search_by_address_or_inscription`

    xhr.open('POST', url);
    xhr.setRequestHeader('X-User-Token', this.userAuthToken);
    xhr.setRequestHeader('X-User-Login', this.userLogin);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');

    let body = JSON.stringify({
      search: document.getElementById('search-field').value
    });

    xhr.onload = () => {
      if (xhr.status == 200) {
        let data = JSON.parse(xhr.responseText);

        let subContent = document.getElementById('search-results');

        if (data.length == 0) {
          subContent.innerHTML = '';
          infoEl.innerHTML = 'O critério de busca informado, não foi encontrado.';
        } else {
          infoEl.innerHTML = '';
          errorEl.innerHTML = '';
          subContent.innerHTML = '';

          let filterData = data.filter(endereco => {
            const regex = /\(\d+\)$/;
            return !regex.test(endereco.endereco);
          });

          filterData.map((data, index) => {

            let resultEl = document.createElement('div');
            resultEl.id = `search-result-${index}`
            resultEl.classList.add('w-100', 'mb-1', 'py-2', 'border-top', 'gb-search-tool-result');

            let addressNameEl = document.createElement('span');
            addressNameEl.classList.add('text-break', 'fs-6', 'text-capitalize');
            addressNameEl.innerHTML = data.endereco

            resultEl.appendChild(addressNameEl);
            resultEl.addEventListener('click', this.getAddressDoors.bind(this, data.endereco));

            subContent.appendChild(resultEl);
          });


        }

        document.body.style.cursor = 'default';
        this.mapa.mapElement.style.cursor = 'default';
      } else {
        errorEl.innerHTML = 'Erro na integração. Recarregue a página e tente novamente.'
        document.body.style.cursor = 'default';
        this.mapa.mapElement.style.cursor = 'default';
      }
    }

    xhr.onerror = () => {
      errorEl.innerHTML = 'Erro na integração. Recarregue a página e tente novamente.'
      document.body.style.cursor = 'default';
      this.mapa.mapElement.style.cursor = 'default';
    }

    xhr.send(body);
  }

  hasActiveGridModules() {
    return [
      'territoriais',
      'autonomas',
      'testadas',
      'avaliacoes',
      'logradouros',
      'trechos',
      'quadras',
      'faces',
      'contribuintes',
      'bairros',
      'distritos',
      'setores',
      'condominios',
      'blocos',
      'zonas',
      'areas',
      'vias',
      'rios',
      'massas_aguas',
      'cursos_dagua',
      'nascentes',
      'unidades_conservacao',
      'areas_preservacao',
      'canais',
      'redes_agua',
      'redes_esgoto',
      'patrimonios_culturais',
      'ordens_servico',
      'inspecoes',
      'acoes_civis',
      'processos_ambientais',
      'micro_drenagens',
      'caixas_ligacao'
    ].includes(module_structure.currentTab)
  }

  activate() {
    this.show();
    this._getElements();
    this._registerEvents();
  }

  deactivate() {
    this.hide();
  }
}

export { SearchTool };
