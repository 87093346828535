import { GeoTool } from '../core/GeoTool';

class MirrorGeom extends GeoTool {

    constructor(config) {
        config = config || {};
        config.tip = config.tip || 'Espelhar geometria';
        config.class = config.class || 'gb-edit-geom';
        config.direction = config.direction || 'x';
        super(config);

    }

    initialize() { };

    activate() {

        super.activate();

        this.select = new ol.interaction.Select();
        this.map.mapElement.style.cursor = 'pointer';

        this.select.on('select', f => {

            let format = new ol.format.GeoJSON();
            let feature = format.writeFeatureObject(f.selected[0]);

            let centroid = turf.centroid(feature);
            let dx = centroid.geometry.coordinates[0];
            let dy = centroid.geometry.coordinates[1];

            turf.coordEach(feature, currentCoord => {

                let x = -(currentCoord[0] - dx) + dx;
                let y = -(currentCoord[1] - dy) + dy;

                currentCoord[0] = this.config.direction == 'x' ? x : currentCoord[0];
                currentCoord[1] = this.config.direction == 'y' ? y : currentCoord[1];

            });

            let tFeature = format.readFeatureFromObject(feature);
            f.selected[0].setGeometry(tFeature.getGeometry());

            this.select.getFeatures().clear();

        });

        this.map.ol.addInteraction(this.select);
    }

    deactivate() {

        this.map.mapElement.style.cursor = 'auto';
        this.map.ol.removeInteraction(this.select);
        this.select = null;
        super.deactivate();

    }
}

export { MirrorGeom };