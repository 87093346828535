import { GeoWidget } from '../core/GeoWidget';

class RotateGeom extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Rotacionar feição';
        config.title = config.title || 'Rotacionar feição';
        config.class = config.class || 'gb-rotate-geom';
        super(config);
        this._select = null;
        this._features = [];
        this._rotatedFeatures = [];
        this._layer = null;
        this._externalSource = config.source;
        this.ui = `
            <div class="p-3">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Selecione a feição que será rotacionada</label><br>
                            <button id="select-btn-${this.id}" type="button" class="btn btn-primary">Selecionar feição</button>
                            <small id="count-text-${this.id}" class="pl-2"> Nenhuma feição selecionada</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Ângulo de Rotação</label>
                            <input id="ang-input-${this.id}" type="number" class="form-control" placeholder="Ângulo de Rotação" value="0" step="0.1" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Ponto de Rotação</label>
                            <div class="form-check">
                                <input id="centroid-check-${this.id}" class="form-check-input" type="radio" name="rotate-point-${this.id}" value="centroid" checked>
                                <label class="form-check-label" for="centroid-check-${this.id}">
                                    Centroide
                                </label>
                            </div>
                            <div class="form-check">
                                <input id="first-check-${this.id}" class="form-check-input" type="radio" name="rotate-point-${this.id}"  value="first">
                                <label class="form-check-label" for="first-check-${this.id}">
                                    Primeiro ponto
                                </label>
                            </div>
                            <div class="form-check">
                                <input id="last-check-${this.id}" class="form-check-input" type="radio" name="rotate-point-${this.id}" value="last">
                                <label class="form-check-label" for="last-check-${this.id}">
                                    Último ponto
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Feição Rotacionada</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="copy-check-${this.id}" >
                                <label class="form-check-label" for="copy-check-${this.id}">
                                Criar como cópia
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <br>
                            <button id="clear-btn-${this.id}" type="button" class="btn btn-secondary btn-block">Limpar</button>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <br>
                            <button id="run-btn-${this.id}" type="button" class="btn btn-primary btn-block" disabled>Rotacionar</button>
                        </div>
                    </div>
                </div>
            </div>`;
    }


    initialize() {

        this._initLayer();
        this._initInteractions();
        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();

        });

        if (!this._externalSource) console.error('Set a source to RotateGeom.')

    };

    _initInteractions() {

        this._select = new ol.interaction.Select();
        this._select.on('select', (e) => {

            this._features = e.target.getFeatures().getArray();
            this._handleCountText();
            this._runBtn.disabled = false;

        });

    }

    _initLayer() {

        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 0, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#f0f',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#f0f'
                    })
                })
            })
        });

        this.map.ol.addLayer(this._layer);

    }

    _registerElements() {

        this._selectBtn = document.getElementById(`select-btn-${this.id}`);
        this._countTxt = document.getElementById(`count-text-${this.id}`);
        this._angInput = document.getElementById(`ang-input-${this.id}`);
        this._clearBtn = document.getElementById(`clear-btn-${this.id}`);
        this._centroidCheck = document.getElementById(`centroid-check-${this.id}`);
        this._firstCheck = document.getElementById(`first-check-${this.id}`);
        this._lastCheck = document.getElementById(`last-check-${this.id}`);
        this._copyCheck = document.getElementById(`copy-check-${this.id}`);
        this._runBtn = document.getElementById(`run-btn-${this.id}`);

    }

    _registerEvents() {

        this._selectBtn.addEventListener('click', () => { this._selectFeaures() });
        this._angInput.addEventListener('input', () => { this._rotateFeaures() });
        this._clearBtn.addEventListener('click', () => { this._clear() });
        this._runBtn.addEventListener('click', () => { this._persist() });
        this._firstCheck.addEventListener('click', () => { this._rotateFeaures() });
        this._lastCheck.addEventListener('click', () => { this._rotateFeaures() });
        this._centroidCheck.addEventListener('click', () => { this._rotateFeaures() });

    }

    _handleCountText() {

        let count = this._features.length;

        switch (count) {
            case 0:
                this._countTxt.innerHTML = 'Nenhuma feição selecionada.'
                break;
            case 1:
                this._countTxt.innerHTML = 'Feição selecionada.'
                break;
            default:
                this._countTxt.innerHTML = count + ' feições selecionadas.'
                break;
        }

    }

    _selectFeaures() {

        this._selectBtn.disabled = true;
        this.map.ol.addInteraction(this._select);

    }

    _clear() {

        this._features = [];
        this._handleCountText();
        this._angInput.value = 0;
        this._rotatedFeatures = [];
        this._runBtn.disabled = true;
        this._layer.getSource().clear();
        this._selectBtn.disabled = false;
        this.map.ol.removeInteraction(this._select);
        this._select.getFeatures().clear();

    }

    _rotateFeaures() {

        let gf = new ol.format.GeoJSON();
        this._layer.getSource().clear();
        this._rotatedFeatures = [];
        let ang = Number(this._angInput.value || 0);
        let isFirstRef = this._firstCheck.checked;
        let isLastRef = this._lastCheck.checked;

        for (let i = 0; i < this._features.length; i++) {

            this._features[i].getGeometry().transform(this.map.srid, 'EPSG:4326');
            let geom = gf.writeGeometryObject(this._features[i].getGeometry());

            let opt = {};
            if (isFirstRef) opt.pivot = this._features[i].getGeometry().getFirstCoordinate();
            if (isLastRef) opt.pivot = this._features[i].getGeometry().getLastCoordinate();

            let rotateGeom = turf.transformRotate(geom, ang, opt);
            let rotateFeature = gf.readFeatureFromObject(rotateGeom);
            rotateFeature.getGeometry().transform('EPSG:4326', this.map.srid);
            this._layer.getSource().addFeature(rotateFeature);

            this._rotatedFeatures.push(rotateFeature);
            this._features[i].getGeometry().transform('EPSG:4326', this.map.srid);

        }


    }

    _persist() {

        let createCopy = this._copyCheck.checked;

        for (let i = 0; i < this._features.length; i++) {

            if (createCopy && this._externalSource) {

                this._externalSource.addFeature(this._rotatedFeatures[i])

            } else {

                let rotatedFeat = this._rotatedFeatures[i];
                let originalFeat = this._features[i];
                originalFeat.setGeometry(rotatedFeat.getGeometry());

            }

        }

        this._clear();

    }

    activate() {

        super.activate();
        this.show();

    }

    deactivate() {

        this._clear();
        this.hide();
        super.deactivate();
    }
}

export { RotateGeom };
