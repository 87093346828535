import { GeoTool } from '../core/GeoTool';

class DuplicateGeom extends GeoTool {

    constructor(config) {
        config.source = config.source || new ol.source.Vector();
        config.tip = config.tip || 'Duplicar geometria';
        config.class = config.class || 'gb-duplicate-geom';
        super(config);
        this._source = config.source;
        this._select = null;
        this._selectClick = null;
    }

    initialize() { };

    activate() {
        super.activate();

        this._addDrawInteraction();

        document.getElementById(this.map.elementId).style.cursor = 'pointer';
    }

    _addDrawInteraction() {
        this._selectClick = new ol.interaction.Select({
          condition: ol.events.condition.click,
        });

        this._selectClick.on('select', (e) => {
          this._select = e.selected[0];
          this._removeFeature();
        });

        this.map.ol.addInteraction(this._selectClick);
    }

    _removeFeature() {
      if (this._select) {
        const copy = this._select.clone();
        this._source.addFeature(copy);
      }
    }

    deactivate() {
      document.getElementById(this.map.elementId).style.cursor = 'auto';
      this.map.ol.removeInteraction(this._selectClick);
      this._select = null;
      this._selectClick = null;

      super.deactivate();
    }
}

export { DuplicateGeom };