import { GeoTool } from '../core/GeoTool';

class Geobuilder3D extends GeoTool {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Visualização 3D';
        super(config);

        this._ol3d = null;

    }

    initialize() {

        Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYjhkNmRhMi1jZjc2LTQyMTgtYmIzNS1mYmU3ZmM4YWU0M2MiLCJpZCI6NjI5MCwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0NTg0NzI4N30.Vh6gppTA4Jrl_ZFeRbZvGYUTrxV-0R4O02RZH4Jg9F4';
        this._ol3d = new olcs.OLCesium({ map: this.map.ol });

        this.map.on('draw', () => {
            this._drawLayers();
        });

        this.map.on('removeLayers', () => {
            this._removeLayers();
        });

    }

    activate() {

        super.activate();
        this.enable3D();

    }

    deactivate() {
        
        this.disable3D();
        super.deactivate();

    }

    enable3D() {

        this._ol3d.setEnabled(true);
        this._drawLayers();
        document.getElementsByClassName('cesium-credit-logoContainer')[0].style.display = 'none';
        this._is3dEnabled = true;

    }

    disable3D() {

        this._ol3d.setEnabled(false);
        this.map.ol.getView().setRotation(0);
        this._ol3d = null;

    }


    _drawLayers() {

        for (let i = 0; i < this.map.content.length; i++) {

            let content = this.map.content[i];

            if (content.display && content.type === 'ion') {

                for (let j = 0; j < content.layers.length; j++) {

                    let confLayer = content.layers[j];

                    if (confLayer.display && confLayer.type.toLowerCase() === 'terrain') {

                        this._ol3d.getCesiumScene().terrainProvider = new Cesium.CesiumTerrainProvider({
                            url: Cesium.IonResource.fromAssetId(confLayer.id)
                        });

                    } else if (confLayer.display && confLayer.type.toLowerCase() === '3dtile') {

                        confLayer.csLayer = new Cesium.Cesium3DTileset({
                            url: Cesium.IonResource.fromAssetId(confLayer.id)
                        });
                        this._ol3d.getCesiumScene().primitives.add(confLayer.csLayer);
                        confLayer.csLayer.style = new Cesium.Cesium3DTileStyle({
                            pointSize: '4.0'
                        });
                    }
                }
            } else if (content.type === 'ogc' && content.defaultService === 'wfs' && content.serviceParameters.extrudField) {

                for (let j = 0; j < content.layers.length; j++) {

                    let confLayer = content.layers[j];

                    if (confLayer.display) {

                        var url = this.map.content[i].layers[j].olLayer.getSource().getUrl();
                        url = url.replace('3857', '4326');

                        this._drawJson(url, content.serviceParameters.extrudField);
                       

                    }
                }
            }

        }

    }

    _drawJson(url, extrudField){

        var self = this;
        var promise = Cesium.GeoJsonDataSource.load(url);
        promise.then(function (dataSource) {
            self._ol3d.getDataSources().add(dataSource);
            var entities = dataSource.entities.values;
            for (var i = 0; i < entities.length; i++) {
                var entity = entities[i];
                entity.polygon.extrudedHeight = entity.properties[extrudField];
            }
        }).otherwise(function (error) {
            self.map.notify(error);
        });
    }

    _removeLayers() {

        for (let i = 0; i < this.map.content.length; i++) {

            let content = this.map.content[i];

            if (content.type === 'ion') {

                for (let j = 0; j < content.layers.length; j++) {

                    let confLayer = content.layers[j];

                    if (confLayer.type.toLowerCase() === 'terrain') {

                        this._ol3d.getCesiumScene().terrainProvider = new Cesium.EllipsoidTerrainProvider({});

                    } else if (confLayer.type.toLowerCase() === '3dtile') {

                        this._ol3d.getCesiumScene().primitives.remove(confLayer.csLayer);

                    }
                }
            }
        }


    }
}

export { Geobuilder3D };