import { GeoButton } from '../core/GeoButton';

class Redo extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Avançar posição';
        config.class = config.class || 'gb-redo-control';
        super(config);
    }

    initialize() { this._registerEvents(); }

    click() {

        this._redoMapState();
        
    }

    _redoMapState() {

        let localStateHistoryUndo = JSON.parse(localStorage.getItem('stateHistory'));
        let localStateHistoryRedo = JSON.parse(localStorage.getItem('stateHistoryRedo'));
        let stateHistoryUndo = [];

        if (localStateHistoryRedo) {

            if(localStateHistoryUndo) {
                Array.prototype.push.apply(stateHistoryUndo, localStateHistoryUndo);
            }

            stateHistoryUndo.push(localStateHistoryRedo[localStateHistoryRedo.length-1]);

            if(localStateHistoryRedo.length < 1) {
                return;
            }

            let lastState = localStateHistoryRedo[localStateHistoryRedo.length-1];

            this.map.ol.getView().animate({
                center: lastState.center,
                zoom: lastState.zoom,
                duration: 300
            });

            localStateHistoryRedo.pop();

            localStorage.setItem('stateHistory', JSON.stringify(localStateHistoryUndo));
            localStorage.setItem('stateHistoryRedo', JSON.stringify(localStateHistoryRedo));
        }

    }

    _registerEvents() {
        
        let self = this;

        window.addEventListener('keyup', function(e){
            if (e.ctrlKey && e.which == 89) {
                self._redoMapState();
            }
        });

    }

}

export { Redo };