import { GeoButton } from '../core/GeoButton';

class ZoomOut extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Afastar';
        config.class = config.class || 'gb-zoomout-control';
        super(config);
    }

    initialize() { }

    click() {
        let zoom = this.map.ol.getView().getZoom() - 1;
        this.map.ol.getView().setZoom(zoom);
    }

}

export { ZoomOut };