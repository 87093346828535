import { GeoTool } from '../core/GeoTool';

class SimpleMeasure extends GeoTool {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Medir';
        config.class = config.class || 'gb-measure-line-control';
        config.measureType = config.measureType || 'length';
        super(config);

        this._measureType = config.measureType;
        this._layer = config.layer;
        this._overlays = [];

    }

    initialize() { }

    _interactionMeasures(primitive) {

        let drawInteraction = this.map.toolbox.draw.getInteraction();
        let ld = this.map.geodesc.lineDescriptor;
        let pd = this.map.geodesc.polygonDescriptor;
        let ad = this.map.geodesc.azimuthDescriptor;
        let overlaysCollection = [];

        drawInteraction.on('drawstart', (e) => {

            let sketch = e.feature;
            let feat = new ol.Feature();

            sketch.getGeometry().on('change', function (evt) {


                feat.setGeometry(evt.target.clone())
                let features = [feat];
                let labelClickCb = function () {
                    drawInteraction.finishDrawing();
                }
                let tolerance = 10;

                if (primitive == 'line') {

                    overlaysCollection = ld({ features, overlaysCollection, labelClickCb, tolerance })

                } else if (primitive == 'polygon') {

                    overlaysCollection = pd({ features, overlaysCollection, labelClickCb })

                } else if (primitive == 'azimuth') {

                    overlaysCollection = ad({ features, overlaysCollection, labelClickCb })

                }

            });

        });

        drawInteraction.on('drawend', (e) => {

            let keys = Object.keys(overlaysCollection);
            keys.forEach(key => {

                this.map.ol.removeOverlay(overlaysCollection[key])

            });

        });


    }

    _clearMeasures() {

        return new Promise((resolve, reject) => {

            let features = this._layer.getSource().getFeatures();
            for (let i = 0; i < features.length; i++) {

                let f = features[i];
                let overlays = f.get('overlays');

                if (overlays) {

                    let keys = Object.keys(overlays);
                    keys.forEach(key => {

                        this.map.ol.removeOverlay(overlays[key])

                    });

                }


                this._layer.getSource().removeFeature(f);

            }

            this._overlays = [];

            new Notify({
                message: 'Todas as medidas foram removidas.',
                type: 'success',
                timeout: 5
            }).show();

            this._showControls();

            resolve();

        });



    }

    _undo() {

        return new Promise((resolve, reject) => {

            let count = this._layer.getSource().getFeatures().length;
            if (count > 0) {

                let feature = this._layer.getSource().getFeatures()[count - 1];
                let overlays = feature.get('overlays');
                let keys = Object.keys(overlays);
                keys.forEach(key => {
                    this.map.ol.removeOverlay(overlays[key])
                });

                this._layer.getSource().removeFeature(feature);

            } else {

                new Notify({
                    message: 'Todas as medidas foram removidas.',
                    type: 'success',
                    timeout: 5
                }).show();

            }

            this._showControls();
            resolve();

        });

    }

    _hideControls() {
        document.querySelectorAll('.gb-control').forEach((el) => {
            el.style.display = 'none';
        });
    }

    _showControls() {
        document.querySelectorAll('.gb-control').forEach((el) => {
            el.style.display = 'block';
        });
    }

    _initMeasure() {
        this._hideControls();

        switch (this._measureType) {

            case 'length':

                this.map.toolbox.draw.getPolyline().then((line) => {

                    let features = [line];
                    let overlayers = this.map.geodesc.lineDescriptor({ features });

                    this._overlays = this._overlays.concat(overlayers);
                    this._layer.getSource().addFeature(line);

                    this.deactivate();

                });

                this._interactionMeasures('line');

                break;

            case 'coordinate':

                this.map.toolbox.draw.getPoint().then((point) => {

                    let features = [point];
                    let overlayers = this.map.geodesc.pointDescriptor({ features });

                    this._overlays = this._overlays.concat(overlayers);
                    this._layer.getSource().addFeature(point);

                    this.deactivate();

                });

                break;

            case 'area':

                this.map.toolbox.draw.getPolygon().then((polygon) => {

                    let features = [polygon];
                    let overlayers = this.map.geodesc.polygonDescriptor({ features })

                    this._overlays = this._overlays.concat(overlayers);
                    this._layer.getSource().addFeature(polygon);

                    this.deactivate();

                });

                this._interactionMeasures('polygon');

                break;

            case 'azimuth':

                this.map.toolbox.draw.getPolyline({ maxPoints: 2 }).then((pline) => {

                    let features = [pline];
                    let overlayers = this.map.geodesc.azimuthDescriptor({ features })

                    this._overlays = this._overlays.concat(overlayers);
                    this._layer.getSource().addFeature(pline);

                    this.deactivate();

                });

                this._interactionMeasures('azimuth');

                break;

            case 'clear':

                this._clearMeasures().then(() => this.deactivate());
                break;


            case 'undo':

                this._undo().then(() => this.deactivate());;
                break;

        }

    }

    activate() {

        super.activate();
        this._initMeasure();

    }

    deactivate() {

        this.map.toolbox.draw.stopDrawing();
        this._showControls();
        super.deactivate();

    }

}

export { SimpleMeasure };