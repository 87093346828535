import { GeoWidget } from '../core/GeoWidget';

class GetPosition extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'rb';
        super(config);

        this.scaleLineControl = null;
        this._hasUI = false;
        this._currentProjection = null;
        this._srsList = config.srsList || [
            {
                name: 'WGS-84 GMS',
                type: 'lat-lng',
                format: 'DMS',
                defs: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
            },
            {
                name: 'WGS-84 GD',
                type: 'lat-lng',
                format: 'DD',
                defs: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
            },
            {
                name: 'SIRGAS 2000 - UTM 22S',
                type: 'projected',
                defs: '+proj=utm +zone=22 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
            }
        ];

    }

    initialize() {

        this._menu = document.createElement('div');
        this._menu.style.width = '300px';
        //this._menu.style.height = '100px';
        this._menu.style.position = 'absolute';
        this._menu.style.top = '0';
        this._menu.style.left = '0';
        this._menu.style.background = '#fff';

        let target = document.getElementById(this.map.target);
        target.appendChild(this._menu);

        this._registerEvents();

    }

    _getMenu() {

        let ul = document.createElement('ul');
        ul.className = 'list-group';

        // let title = document.createElement('li');
        // title.className = 'list-group-item p-2 active bg-dark';
        // title.innerHTML = 'Obter coordenadas:';
        // ul.appendChild(title);

        for (let i = 0; i < this._srsList.length; i++) {

            let srs = this._srsList[i];
            let li = document.createElement('li');
            li.className = 'list-group-item list-group-item-action flex-column align-items-start p-2';
            li.style.cursor = 'pointer';
            
            this._currentProjection = srs;
            let projectedCoord = this._projectPoint(this._currentCoord);
            let formatedCoord = this._formatCoord(projectedCoord);

            let template = `
                <div class="input-group input-group-sm m-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text">${srs.name}</span>
                    </div>
                    <input value="${formatedCoord}" type="text" class="form-control" aria-label="Coordenadas" aria-describedby="inputGroup-sizing-sm">
                </div>
            `;

            li.innerHTML = template;
            ul.appendChild(li);

        }

        this._menu.innerHTML = '';
        this._menu.appendChild(ul);

    }

    _setMenuPosition(x, y) {

        this._menu.style.left = x + 'px';
        this._menu.style.top = y + 'px';
        this._menu.style.right = 'auto';
        this._menu.style.bottom = 'auto';

    }

    _showMenu() {

        this._menu.style.display = 'block';

    }

    _hideMenu() {

        this._menu.style.display = 'none';

    }

    _projectPoint(coordinate) {

        let wgsCoords = ol.proj.transform(coordinate, this.map.srid, 'EPSG:4326');
        return proj4(this._currentProjection.defs, wgsCoords);

    }

    _formatCoord(coordinate) {

        let displayCoords = '';

        if (this._currentProjection.format === 'DMS') {

            displayCoords = ol.coordinate.toStringHDMS(coordinate, this.map.config.precision || 3);

        } else if (this._currentProjection.format === 'DD') {

            displayCoords = coordinate.map((e) => {
                return Number(e.toFixed(8));
            });
            displayCoords = displayCoords.toString();

        } else {

            displayCoords = coordinate.map((e) => {
                return Number(e.toFixed(this.map.config.precision || 3));
            });
            displayCoords = displayCoords.toString();

        }

        return displayCoords;

    }

    _registerEvents() {

        this.map.ol.getViewport().addEventListener('contextmenu', (evt) => {

            evt.preventDefault();
            this._currentCoord = this.map.ol.getEventCoordinate(evt);
            this._setMenuPosition(evt.offsetX, evt.offsetY);
            this._getMenu();
            this._showMenu();

        });

        this.map.ol.getView().on('change:resolution', () => {

            this._hideMenu();

        });

        window.addEventListener('click', (e) => {

            if (!this._menu.contains(e.target)) {
                this._hideMenu();
            }

        });

        document.onkeydown = (evt) => {

            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this._hideMenu();
            }

        };


    }

}

export { GetPosition };