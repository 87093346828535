import { GeoWidget } from '../core/GeoWidget';

class PointsByDistSegment extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'PointsByDistSegment';
        config.title = config.title || 'PointsByDistSegment';
        config.class = config.class || 'point-by-dist-segment';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._isListening = false;
        this._lastPoint = null;
        this._feature = new ol.Feature(null);
        this._mouseFeature = new ol.Feature(null);
        this._pointsList = [];

        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();

        });

    }

    _initInternalLayer() {

        this._vectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });
        this._vectorLayer.setZIndex(99999);
        this._vectorLayer.getSource().addFeature(this._mouseFeature);

        map.ol.addLayer(this._vectorLayer);

    }

    _getUiTemplate() {

        return `
            <div class='m-0 p-3'>

                <p class='gb-measure-tip'>Digite a distância desejada e insira um segmento no mapa.</p>

                <div class='row pt-1 pb-1 pl-3 pr-3'>

                    <div class="input-group input-group-sm mb-3">
                        <input id="gb-point-dist-input-${this.id}" min="0.01" type="number" value="${this._config.defaultDistance}" class="form-control">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">metros</span>
                        </div>
                    </div>

                </div>

                <div class='row pt-1 pb-1 pl-3 pr-3'>

                   <button id="gb-point-dist-btn-${this.id}" class="btn btn-dark btn-block btn-sm">Inserir Segmento</button>

                </div>

            </div>
        `;

    }

    _registerElements() {

        this._inputDistElement = document.getElementById(`gb-point-dist-input-${this.id}`);
        this._btnElement = document.getElementById(`gb-point-dist-btn-${this.id}`);

    }

    _managerInteractionActions() {

        if (!this._isRunning) {

            this._isRunning = true;
            this._btnElement.innerHTML = 'Parar';
            document.getElementById(this.map.elementId).style.cursor = 'crosshair';

        } else {

            this._isRunning = false;
            this._btnElement.innerHTML = 'Iniciar';
            document.getElementById(this.map.elementId).style.cursor = 'auto';
            this._mouseFeature.setGeometry(this._createFeature([0, 0], 'point').getGeometry());
            this._pointsList = [];

        }


    }

    _clickHandler(evt) {

        if (this._isRunning) {

            this._isListening = !this._isListening;
            this._lastPoint = this._projectPoint(evt.coordinate);
            this._pointsList.push(this._lastPoint);
            this._geomHandler({
                coord: evt.coordinate,
                action: 'click'
            });

        }

    }

    _moveHandler(evt) {

        if (this._isRunning) {

            this._geomHandler({
                coord: evt.coordinate
            });

        }

    }


    _createFeature(coords, geomType) {

        let feature;

        switch (geomType.toLowerCase()) {
            case 'point':
                feature = new ol.Feature(new ol.geom.Point(coords));
                break;
            case 'linestring':
                feature = new ol.Feature(new ol.geom.LineString(coords));
                break;
            case 'polygon':
                feature = new ol.Feature(new ol.geom.Polygon(coords));
                break;
        }

        return feature;

    }

    _geomHandler(p) {

        let geomType = this._config.geometryType.toLowerCase();
        let action = p.action;
        let coord = p.coord;
        let feature;

        if (action === 'click') {

            if (this._pointsList.length > 1) {

                let pointsMouse = this._pointsList.slice();
                pointsMouse.push(coord);
                //feature = this._createFeature(pointsMouse, geomType);

                this._mouseFeature.setGeometry(this._createFeature([0, 0], 'point').getGeometry());
               // this._source.addFeature(feature);

                this._calculatePoints();

                this._pointsList = [];

            } else {

                let pointsMouse = this._pointsList.slice();
                pointsMouse.push(coord);
                this._mouseFeature.setGeometry(this._createFeature(pointsMouse, geomType).getGeometry());

            }

        } else {

            let pointsMouse = this._pointsList.slice();
            pointsMouse.push(coord);
            this._mouseFeature.setGeometry(this._createFeature(pointsMouse, geomType).getGeometry());

        }

    }

    _calculatePoints() {

        let p1 = this._pointsList[0];
        let p2 = this._pointsList[1];
        let distance = this._distance(p1, p2);

        if (this._config.defaultDistance > distance) {
            this.map.notify("Distância entre os pontos do segmento é menor que a distância mínima entre os pontos.");
            return;
        }


        let numPtsSegmento = Math.floor(distance / this._config.defaultDistance);
        let azimuth = this._azimuthBetweenPoints(p1, p2);

        for (let i = 0; i <= numPtsSegmento; i++) {

            let coords = this._pointAzDist(p1, azimuth, this._config.defaultDistance * i);
            let feature = this._createFeature(coords, 'point');
            this._source.addFeature(feature);

        }


    }

    _azimuthBetweenPoints(p1, p2) {

        return Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);

    }

    _pointAzDist(p0, az, dist) {

        let x = p0[0] + dist * Math.sin(az);
        let y = p0[1] + dist * Math.cos(az);

        return [x, y];

    }

    _distance(p1, p2) {

        return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));

    }

    _projectPoint(coordinate) {

        return ol.proj.transform(coordinate, this.map.srid, 'EPSG:3857');

    }

    _registerEvents() {

        this._btnElement.addEventListener('click', () => this._managerInteractionActions());
        this._inputDistElement.addEventListener('change', () => {
            this._config.defaultDistance = parseFloat(this._inputDistElement.value);
            console.log(this._config.defaultDistance)
        });
        this.map.ol.on('click', (evt) => this._clickHandler(evt));
        this.map.ol.on('pointermove', (evt) => this._moveHandler(evt));

    }

    activate() {

        this.show();

    }

    deactivate() {

        this._managerInteractionActions();
        this.hide();

    }

}

export { PointsByDistSegment };