import { GeoWidget } from '../core/GeoWidget';

class GeomFromWKT extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'GeomFromWKT';
        config.title = config.title || 'GeomFromWKT';
        config.class = config.class || 'geom-from-wkt';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._isListening = false;
        this._lastPoint = null;
        this._feature = new ol.Feature(null);
        this._mouseFeature = new ol.Feature(null);
        this._pointsList = [];

        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();

        });

    }

    _initInternalLayer() {

        this._vectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });
        this._vectorLayer.setZIndex(99999);
        this._vectorLayer.getSource().addFeature(this._mouseFeature);

        map.ol.addLayer(this._vectorLayer);

    }

    _getUiTemplate() {

        return `
        <div class='m-0 p-3'>

            <div class="row">
        
                <div class="col">
                    <label for="gb-pbi-wkt-${this.id}">WKT</label>
                    <input type="text" class="form-control" id="gb-pbi-wkt-${this.id}">
                </div>
        
                <div class="col-4">
                    <label for="gb-pbi-src-${this.id}">SRC</label>
                    <select id="gb-pbi-src-${this.id}" class="form-control">
                        <option value="EPSG:3857" selected>Pseudo Mercator - SIRGAS 2000</option>
                        <option value="EPSG:4326">Lon, Lat - SIRGAS 2000</option>
                    </select>
                </div>
        
                <div class="col-2">
                    <label style="color: #fff"> _</label>
                    <br>
                    <button id="gb-pbi-add-wkt-${this.id}" class="btn btn-primary">Add</button>
                </div>
            </div>
        </div>
        `;

    }

    _registerElements() {

        this._inputWktElement = document.getElementById(`gb-pbi-wkt-${this.id}`);
        this._btnAddWktElement = document.getElementById(`gb-pbi-add-wkt-${this.id}`);
        this._selectSrcElement = document.getElementById(`gb-pbi-src-${this.id}`);

    }

    _createFromWkt(wkt){

        try{

            let format = new ol.format.WKT();

            let feature = format.readFeature(wkt, {
                dataProjection: this._selectSrcElement.value || 'EPSG:4326',
                featureProjection: this.map.ol.getView().getProjection().getCode()
            });
    
            this._source.addFeature(feature);

        } catch(e){

            this.map.notify("Não foi possível inserir a geometria desejada.<br> WKT mal formatado.");

        }

    }



    _registerEvents() {

        this._btnAddWktElement.addEventListener('click', () => {
            
            let wkt = this._inputWktElement.value;
            this._createFromWkt(wkt);

        });

    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { GeomFromWKT };