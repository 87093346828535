import { GeoWidget } from '../core/GeoWidget';

class Eaves extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Eaves';
        config.title = config.title || 'Eaves';
        config.class = config.class || 'eaves';
        config.defaultDistance = config.defaultDistance || 0.5;
        super(config);

        this._mainFeature = config.mainFeature || new ol.Feature();
        this._eavesFeature = config.eavesFeature || new ol.Feature();
        this._lastPoint = null;
        this._feature = new ol.Feature(null);
        this._mouseFeature = new ol.Feature(null);
        this._pointsList = [];

        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();

        });

    }

    _initInternalLayer() {

        this._vectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });
        this._vectorLayer.setZIndex(99999);
        this._vectorLayer.getSource().addFeature(this._mouseFeature);

        map.ol.addLayer(this._vectorLayer);

    }

    _getUiTemplate() {

        return `
            <div class='m-0 p-3'>

                <p class='gb-measure-tip'>Digite o tamanho do beiral. </p>

                <div class='row pt-1 pb-1 pl-3 pr-3'>

                    <div class="input-group input-group-sm mb-3">
                        <input id="gb-input-${this.id}" min="0.01" step="0.1" type="number" value="${this._config.defaultDistance}" class="form-control">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">metros</span>
                        </div>
                    </div>

                </div>

                <div class='row pt-1 pb-1 pl-3 pr-3'>

                   <button id="gb-btn-${this.id}" class="btn btn-dark btn-block btn-sm">Iniciar</button>

                </div>

            </div>
        `;

    }

    _generateEaves() {

        if (this._mainFeature.getGeometry()) {

            var f = new ol.format.GeoJSON();

            this._mainFeature.getGeometry().transform(this.map.srid, 'EPSG:4326');

            let mainCoords, mainPolygon, mainPolygonBuffered, eavesPolygon, mainLineString, mainPolyEaves, mainPolyEaves2;
            let bufferSize = parseFloat(this._inputElement.value);

            if (this._mainFeature.getGeometry().getType().toLowerCase() === 'multipolygon') {
                mainCoords = this._mainFeature.getGeometry().getCoordinates()[0];
            } else {
                mainCoords = this._mainFeature.getGeometry().getCoordinates();
            }

            mainPolygon = turf.polygon(mainCoords);
            mainLineString = turf.lineString(mainCoords[0]);
            mainPolygonBuffered = turf.buffer(mainLineString, bufferSize, {units: 'meters', steps: 256});

            eavesPolygon = turf.intersect(mainPolygonBuffered, mainPolygon);
            mainPolyEaves = turf.difference(mainPolygon, eavesPolygon);
      

            let mainFeature = f.readFeatureFromObject(mainPolyEaves);
            mainFeature.getGeometry().transform('EPSG:4326', this.map.srid);

            let eavesFeature = f.readFeatureFromObject(eavesPolygon);
            eavesFeature.getGeometry().transform('EPSG:4326', this.map.srid);

            this._mainFeature.setGeometry(mainFeature.getGeometry());
            this._eavesFeature.setGeometry(eavesFeature.getGeometry());
            

        }

    }

    _clearPolygon(poly, cutLine){




        return poly;

    }

    _registerElements() {

        this._inputElement = document.getElementById(`gb-input-${this.id}`);
        this._btnElement = document.getElementById(`gb-btn-${this.id}`);

    }

    _managerInteractionActions() {

        this._generateEaves();

    }

    _registerEvents() {

        this._btnElement.addEventListener('click', () => this._managerInteractionActions());
        this._inputElement.addEventListener('change', () => {
            this._config.defaultDistance = parseFloat(this._inputElement.value);
            console.log(this._config.defaultDistance)
        });

    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { Eaves };