import { GeoWidget } from '../core/GeoWidget';

class PolygonToMultiPolygon extends GeoWidget {

    constructor(config) {
        config.source = config.source || new ol.source.Vector();
        config.tip = config.tip || 'Transformar polygon para multpolygon';
        config.title = config.title || 'Transformar polygon para multpolygon';
        config.class = config.class || 'gb-poly-to-multi';
        super(config);
        this._source = config.source;
        this._selectClick = null;
        this._multiPolygon = null;
        this._btnTransform = null;
        this.ui = this._getUiTemplate();
    }

    initialize() {
      this.on('ready', () => {
          this._registerElements();
          this._registerEvents();
      });
    };

    activate() {
        this.show();

        this._addSelectInteraction();
        document.getElementById(this.map.elementId).style.cursor = 'pointer';
    }

    _getUiTemplate() {

      return `
        <div class='m-0 p-3 d-flex flex-column'>
          <p>Pressione Shift e selecione as geometrias <br /> que você deseja transformar.</p>
          <br />
          <button id="gb-transform-ptm-${this.id}" class="btn btn-primary">Transformar</button>
        </div>
      `;
    }

    _registerElements() {
      this._btnTransform = document.getElementById(`gb-transform-ptm-${this.id}`);
    }

    _registerEvents() {
      this._btnTransform.addEventListener('click', () => this._transformFeature());
    }

    _addSelectInteraction() {
      this._selectClick = new ol.interaction.Select({
        condition: ol.events.condition.click,
        multi: true,
      });

      this.map.ol.addInteraction(this._selectClick);
    }

    _transformFeature() {
      const features = this._selectClick.getFeatures().array_;
      if (features.length >= 2) {
        const isDiferent = features.find(poly => poly.getGeometry().getType() != 'Polygon');

        if (isDiferent) {
          this.map.notify("Geometria selecionada não é do tipo Polygon.");
          return;
        }

        const geometries = features.map(poly => poly.getGeometry().clone());

        this._multiPolygon = new ol.geom.MultiPolygon(geometries);
        const feat = new ol.Feature(this._multiPolygon);
        this._source.addFeature(feat);

        features.forEach(oldFeauture => {
          this._source.removeFeature(oldFeauture);
        });
      } else {
        this.map.notify("Selecione pelo menos 2 geometrias.");
      }
    }

    deactivate() {
      document.getElementById(this.map.elementId).style.cursor = 'auto';
      this.map.ol.removeInteraction(this._selectClick);
      this._selectClick = null;
      this._multiPolygon = null;

      this.hide();
    }
}

export { PolygonToMultiPolygon };