import { GeoTool } from '../core/GeoTool';

class MoveGeom extends GeoTool {

    constructor(config) {
      config.source = config.source || new ol.source.Vector();
      config.tip = config.tip || 'Mover geometria';
      config.class = config.class || 'gb-move-geom';
      super(config);
      this._source = config.source;
      this._feature = config.feature || '';
      this._pointerInteraction = null;
      this._coordinate = null;
      this._cursor = 'pointer';
      this._previousCursor = undefined;
      this._active = false;
    }

    initialize() { };

    activate() {
      super.activate();
      this._drag();
    }

    deactivate() {

      this.map.ol.removeInteraction(this._pointerInteraction);
      this._pointerInteraction = null;

      super.deactivate();
    }

    _drag() {
      this._pointerInteraction = new ol.interaction.Pointer({
        handleDownEvent: this._handleDownEvent,
        handleDragEvent: this._handleDragEvent,
        handleMoveEvent: this._handleMoveEvent,
        handleUpEvent: this._handleUpEvent
      });

      this.map.ol.addInteraction(this._pointerInteraction);
    }

    _handleDownEvent(evt) {
      var map = evt.map;
    
      var feature = map.forEachFeatureAtPixel(evt.pixel,
        function(feature) {
          return feature;
        }
      );
    
      if (feature) {
        this._coordinate = evt.coordinate;
        this._feature = feature;
      }
    
      return !!feature;
    }

    _handleDragEvent(evt) {
      var deltaX = evt.coordinate[0] - this._coordinate[0];
      var deltaY = evt.coordinate[1] - this._coordinate[1];
    
      var geometry = this._feature.getGeometry();
      geometry.translate(deltaX, deltaY);
    
      this._coordinate[0] = evt.coordinate[0];
      this._coordinate[1] = evt.coordinate[1];
    }

    _handleMoveEvent(evt) {
      if (this._cursor) {
        var map = evt.map;
        var feature = map.forEachFeatureAtPixel(evt.pixel,
          function(feature) {
            return feature;
          });
        var element = evt.map.getTargetElement();
        if (feature) {
          if (element.style.cursor != this._cursor) {
            this._previousCursor = element.style.cursor;
            element.style.cursor = this._cursor;
          }
        } else if (this._previousCursor !== undefined) {
          element.style.cursor = this._previousCursor;
          this._previousCursor = undefined;
        }
      }
    }

    _handleUpEvent() {
      this._coordinate = null;
      this._feature = null;
      return false;
    }

}

export { MoveGeom };