import { GeoWidget } from '../core/GeoWidget';

class Transparency extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Controle de Transparência';
        config.title = config.title || 'Controle de Transparência';
        config.class = config.class || 'gb-transp-control';
        config.docked = config.docked || false;
        config.minWidth = config.minWidth || '300';
        config.minHeight = config.minHeight || '400';
        config.maxWidth = config.maxWidth || '400';
        config.maxHeight = config.maxHeight || '600';
        super(config);

        this._layersSelectElm = null;
        this._sliderElm = null;
        this._valueElm = null;
        this._minElm = null;
        this._maxElm = null;

        this.ui = this._builUi();

    }

    initialize() {
        
        this.on('ready', () => {

            this._getElements();
            this._registerEvents();

        });

    }

    _builUi() {

        return `
        <div class="p-3">
            <div class="form-group">
                <label>Camada</label>
                <select class="form-control" id="gb-t${this.id}-camada"></select>
            </div>
            <div class="form-group">
                <label>Transparência</label>
                <div class="row">
                    <div class="col">
                        <input type='range' min='0' max='100' value='0' class='gb-form-control' id='gb-t${this.id}-slider'>
                    </div>
                    <div class="col">
                        <input type='number' min='0' max='100' value='0' class='form-control' id='gb-t${this.id}-valor'>
                    </div>
                </div>

                <div class="row pt-3">
                    <div class="col">
                        <a class="btn btn-dark btn-block" href="#" role="button" id='gb-t${this.id}-min'>Transparente</a>
                    </div>
                    <div class="col">
                        <a class="btn btn-dark btn-block" href="#" role="button"  id='gb-t${this.id}-max'>Opaco</a>
                    </div>
                </div>
            </div>
        </div>`;

    }

    _getElements() {

        this._layersSelectElm = document.getElementById(`gb-t${this.id}-camada`);
        this._sliderElm = document.getElementById(`gb-t${this.id}-slider`);
        this._valueElm = document.getElementById(`gb-t${this.id}-valor`);
        this._minElm = document.getElementById(`gb-t${this.id}-min`);
        this._maxElm = document.getElementById(`gb-t${this.id}-max`);

    }

    _listLayers() {

        this._layersSelectElm.innerHTML = '';

        for (let i = 0; i < this.map.content.length; i++) {

            if (this.map.content[i].olLayer) {

                this._layersSelectElm.innerHTML += `<option group="${i}">${this.map.content[i].name}</option>`;

            } else {

                for (let j = 0; j < this.map.content[i].layers.length; j++) {

                    if (this.map.content[i].layers[j].olLayer) {

                        this._layersSelectElm.innerHTML += `<option group="${i}" layer="${j}">${this.map.content[i].layers[j].name}</option>`;

                    }

                }

            }

        }

    }

    _getOlLayer() {

        let group = this._layersSelectElm.selectedOptions[0].getAttribute('group');
        let layer = this._layersSelectElm.selectedOptions[0].getAttribute('layer');

        if (group && layer) {

            this._curruentOlLayer = this.map.content[group].layers[layer].olLayer;

        } else {

            this._curruentOlLayer = this.map.content[group].olLayer;

        }

    }

    _setTransparency() {

        let val = parseInt(this._sliderElm.value);
        let opacity = 1 - val / 100;
        this._getOlLayer();
        this._curruentOlLayer.setOpacity(opacity);

    }

    _getTransparency() {

        this._getOlLayer();
        let opacity = this._curruentOlLayer.getOpacity();
        let transp = parseInt((1 - opacity) * 100);
        this._sliderElm.value = transp;
        this._valueElm.value = transp;

    }

    _registerEvents() {

        this._layersSelectElm.addEventListener('change', () => {

            this._getTransparency();

        });

        this._sliderElm.addEventListener('change', () => {

            this._valueElm.value = this._sliderElm.value;
            this._setTransparency();

        });

        this._valueElm.addEventListener('change', () => {

            if (this._valueElm.value < 0) {

                this._valueElm.value = 0;

            } else if (this._valueElm.value > 100) {

                this._valueElm.value = 100;

            }

            this._sliderElm.value = this._valueElm.value;
            this._setTransparency();

        });

        this._minElm.addEventListener('click', () => {

            this._valueElm.value = 100;
            this._sliderElm.value = 100;
            this._setTransparency();

        });

        this._maxElm.addEventListener('click', () => {

            this._valueElm.value = 0;
            this._sliderElm.value = 0;
            this._setTransparency();

        });

    }

    activate() {

        super.activate();
        this._listLayers();
        this._getTransparency();
        this.show();

    }

    deactivate() {

        this.hide();
        super.deactivate();
    }

}

export { Transparency };