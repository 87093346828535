import { GeoWidget } from '../core/GeoWidget';

class ArcsByThreePoints extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Geração de arcos por três pontos';
        config.title = config.title || 'Geração de arcos por três pontos';
        config.class = config.class || 'gb-arc-by-three';
        config.geometryType = 'linestring';
        config.defaultDistance = config.defaultDistance || 1;
        config.defaultUnits = config.defaultUnits || 'm';
        super(config);

        this._source = config.source || new ol.source.Vector();
        this._isListening = false;
        this._lastPoint = null;
        this._bezierFeature = new ol.Feature(null);
        this._mouseFeature = new ol.Feature(null);
        this._pointsList = [];
        this._select = null;
        this._dragBox = null;
        this._mapEvent = null;

        this.ui = this._getUiTemplate();
    }

    initialize() {
      this.on('ready', () => {
        this._registerElements();
        this._registerEvents();
        this._initInternalLayer();
        this._createInteractions();
      });
    }

    _getUiTemplate() {
        return `
          <div class='m-0 p-3' style="max-width:400px">
              <p class='gb-measure-tip'>Clique em iniciar e selecione um conjunto de pontos no mapa.</p>
              <div class='row pt-1 pb-1 pl-3 pr-3'>
                  <button id="gb-btn-${this.id}" class="btn btn-dark btn-block btn-sm">Selecionar Elementos</button>
              </div>
          </div>
        `;
    }


    _registerElements() {
        this._btnElement = document.getElementById(`gb-btn-${this.id}`);
    }

    _createInteractions() {
        this._select = new ol.interaction.Select();

        this._dragBox = new ol.interaction.DragBox({
            condition: ol.events.conditionplatformModifierKeyOnly
        });

        this._dragBox.on('boxend', () => {
            var rotation = this.map.ol.getView().getRotation();
            var oblique = rotation % (Math.PI / 2) !== 0;
            var candidateFeatures = oblique ? [] : this._selectedFeatures;
            var extent = this._dragBox.getGeometry().getExtent();
            this._source.forEachFeatureIntersectingExtent(extent, function (feature) {
                candidateFeatures.push(feature);
            });

            if (oblique) {
                var anchor = [0, 0];
                var geometry = this._dragBox.getGeometry().clone();
                geometry.rotate(-rotation, anchor);
                var extent$1 = geometry.getExtent();
                candidateFeatures.forEach(function (feature) {
                    var geometry = feature.getGeometry().clone();
                    geometry.rotate(-rotation, anchor);
                    if (geometry.intersectsExtent(extent$1)) {
                        selectedFeatures.push(feature);
                    }
                });
            }

        });

        this._dragBox.on('boxstart', () => {
            this._selectedFeatures.clear();
        });

        this._selectedFeatures = this._select.getFeatures();
    }

    _OnPointerUp() {
        setTimeout(() => {
            this._drawBezier();
        }, 200);
    }

    _addInteractions() {

        const index = this._source.getFeatures().indexOf(this._bezierFeature);

        if (index === -1) {
            this._source.addFeature(this._bezierFeature);
        }

        this._source.getFeatures()

        this.map.ol.addInteraction(this._select);

        this.map.ol.addInteraction(this._dragBox);

        this._mapEvent = this.map.ol.on('pointerup', () => this._OnPointerUp());
    }

    _initInternalLayer() {

        this._vectorLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });
        this._vectorLayer.setZIndex(99999);
        this._vectorLayer.getSource().addFeature(this._mouseFeature);

        map.ol.addLayer(this._vectorLayer);

    }

    _drawBezier() {

        if (this._selectedFeatures.getLength() === 3) {

            let geoJsonFormat = new ol.format.GeoJSON();
            let features = this._selectedFeatures.getArray();
            let pointFeatures = [];

            features.forEach(feature => {

                let featureType = feature.getGeometry().getType().toLocaleLowerCase();
                if (featureType === 'point') {

                    pointFeatures.push(feature);

                }

            });

            if (pointFeatures.length > 2) {

                let lineArray = pointFeatures.map(function (point) {
                    return point.getGeometry().getCoordinates();
                });
                let bezier = turf.bezierSpline(turf.lineString(lineArray), {
                    resolution: this._resolution,
                    sharpness: this._sharpness
                });
                let bf = geoJsonFormat.readFeatureFromObject(bezier);

                this._bezierFeature.setGeometry(bf.getGeometry());

            }

        } else {
          this.map.notify("Selecione 3 pontos para gerar um arco.");
        }

    }

    _actionsHandler() {
      this._addInteractions();
    }

    _registerEvents() {
      this._btnElement.addEventListener('click', () => this._actionsHandler());
    }

    activate() {
      this.show();
    }

    deactivate() {
      this.hide();
      this.map.ol.removeInteraction(this._select);
      this.map.ol.removeInteraction(this._dragBox);
      this.map.ol.removeEventListener('pointerup', this._mapEvent);
    }

}

export { ArcsByThreePoints };