import { GeoButton } from '../core/GeoButton';

class RedoGeom extends GeoButton {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Avançar posição';
        config.class = config.class || 'gb-redo-geom';
        super(config);
        this._source = config.source;
        this._stateHistory = config.stateHistory,
        this._stateHistoryRedo = config.stateHistoryRedo
    }

    initialize() { 
    
        this._registerEvents();

    }

    click() {

        this._redoGeomState();

    }

    _redoGeomState() {
        if (this._stateHistoryRedo.length > 0) {
            let lastState = this._stateHistoryRedo[this._stateHistoryRedo.length-1];

            if (lastState.action === 'addfeature') {
              this._source.removeFeature(lastState.feature);
            }
            else if (lastState.action === 'removefeature') {
              this._source.addFeature(lastState.feature);
            }

            this._stateHistoryRedo.pop();
        }
    }

    _registerEvents() {
        
        let self = this;

        window.addEventListener('keyup', function(e){
            if (e.which == 89 && e.altKey) {
                self._redoGeomState();
            }
        });

    }

}

export { RedoGeom };