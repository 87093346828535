import { GeoButton } from '../core/GeoButton';

class CreateGeom extends GeoButton {

    constructor(config) {

        config = config || {};
        config.source = config.source || new ol.source.Vector();
        config.tip = config.tip || 'Criar geometria';
        config.class = config.class || 'gb-create-geom';
        super(config);
        this._source = config.source;
        this._geomType = config.geomType || 'Point';
        this._feature = config.feature || '';
        this._layer = null;
        this._draw = null;
        this._snap = null;
    }

    initialize() { };

    click() {
        this._addDrawInteraction();

        document.getElementById(this.map.elementId).style.cursor = 'crosshair';

        this._removeInteraction();
    }

    _addDrawInteraction() {
        this._draw = new ol.interaction.Draw({
            source: this._source,
            type: this._geomType,
        });

        this.map.ol.addInteraction(this._draw);
        this._snap = new ol.interaction.Snap({ source: this._source });
        this.map.ol.addInteraction(this._snap);
    }

    _removeInteraction() {

        this._draw.once('drawend', (evt) => {

            document.getElementById(this.map.elementId).style.cursor = 'auto';
            this.map.ol.removeInteraction(this._draw);
            this.map.ol.removeInteraction(this._snap);
            this._draw = null;
            this._snap = null;

        });
    }
}

export { CreateGeom };