import { GeoWidget } from '../core/GeoWidget';

class ScaleLine extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.hasUI = false;
        config.position = config.position || 'rb';
        super(config);
        
        this.scaleLineControl = null;
        this._hasUI = false;

    }

    initialize() {

        let panelElementId = this.map.getPanel(this._config.position).elementId;
        this._panelElement = document.getElementById(panelElementId);

        this.scaleLineControl = new ol.control.ScaleLine();
        this.scaleLineControl.setTarget(panelElementId);
        this.scaleLineControl.element.classList.remove('ol-scale-line');
        this.scaleLineControl.element.style.padding = '3px';

        this.map.ol.addControl(this.scaleLineControl);
        
    }

}

export { ScaleLine };