import { GeoTool } from '../core/GeoTool';

class LayerSwipe extends GeoTool {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Layer Swipe';
        config.class = config.class || 'gb-zoom-rect-control';
        super(config);

        this._layersSelectElm = null;
        this._layer = null;
        this._rangeElm = null;
        this._evtHandlerPos = null;
        this._evtHandlerPre = null;

    }

    initialize() {

        this._createElms();
        this._registerUiEvents();

    }

    _createElms() {

        this._layersSelectElm = document.createElement('select');
        this._layersSelectElm.style.position = 'absolute';
        this._layersSelectElm.style.top = '10px';
        this._layersSelectElm.style.right = '52px';
        this._layersSelectElm.style.width = '200px';
        this._layersSelectElm.style.zIndex = '9999';

        this.map.mapElement.append(this._layersSelectElm);

        this._rangeElm = document.createElement('input');
        this._rangeElm.className = 'gb-slider-full';
        this._rangeElm.style.position = 'absolute';
        this._rangeElm.style.top = '50%';
        this._rangeElm.style.left = '0';
        this._rangeElm.style.width = '100%';

        this._rangeElm.type = 'range';
        this._rangeElm.value = '50';
        this._rangeElm.style.zIndex = '9999';

        this.map.mapElement.append(this._rangeElm);

        this._decoratorElm = document.createElement('div');
        this._decoratorElm.style.position = 'absolute';
        this._decoratorElm.style.top = '0';
        this._decoratorElm.style.left = '50%';
        this._decoratorElm.style.width = '5px';
        this._decoratorElm.style.height = '100%';
        this._decoratorElm.style.background = '#000';
        this._decoratorElm.style.zIndex = '9998';

        this._postionElm = document.createElement('div');
        this._postionElm.style.position = 'absolute';
        this._postionElm.style.top = '50%';
        this._postionElm.style.left = '-10px';
        this._postionElm.style.width = '20px';
        this._postionElm.style.height = '20px';
        this._postionElm.style.background = '#000';

        this._decoratorElm.append(this._postionElm);
        this.map.mapElement.append(this._decoratorElm);

        this._layersSelectElm.style.display = 'none';
        this._rangeElm.style.display = 'none';
        this._decoratorElm.style.display = 'none';

    }

    _listLayers() {

        this._layersSelectElm.innerHTML = '';

        for (let i = 0; i < this.map.content.length; i++) {

            if (this.map.content[i].olLayer && this.map.content[i].display) {

                this._layersSelectElm.innerHTML += `<option group="${i}">${this.map.content[i].name}</option>`;

            } else {

                for (let j = 0; j < this.map.content[i].layers.length; j++) {

                    if (this.map.content[i].layers[j].olLayer && this.map.content[i].layers[j].display) {

                        this._layersSelectElm.innerHTML += `<option group="${i}" layer="${j}">${this.map.content[i].layers[j].name}</option>`;

                    }

                }

            }

        }

    }

    _getLayer() {

        let i = this._layersSelectElm.selectedOptions[0].getAttribute('group');
        let j = this._layersSelectElm.selectedOptions[0].getAttribute('layer');

        if (j) {

            this._layer = this.map.content[i].layers[j].olLayer;

        } else {

            this._layer = this.map.content[i].olLayer;

        }

    }

    _registerUiEvents() {

        this._rangeElm.addEventListener('input', () => {

            this._decoratorElm.style.left = this._rangeElm.value + '%';
            this.map.ol.render();

        });

        this._layersSelectElm.addEventListener('change', () => {

            this._unregisterLayerEvents();
            this._getLayer();
            this._registerLayerEvents();
            this.map.ol.render();

        });


    }

    _registerLayerEvents() {


        var map = this.map.ol;
        var swipe = this._rangeElm;

        this._evtHandlerPre = this._layer.on('prerender', function (evt) {
            var ctx = evt.context;
            var mapSize = map.getSize();
            var width = mapSize[0] * (swipe.value / 100);
            var tl = ol.render.getRenderPixel(evt, [width, 0]);
            var tr = ol.render.getRenderPixel(evt, [mapSize[0], 0]);
            var bl = ol.render.getRenderPixel(evt, [width, mapSize[1]]);
            var br = ol.render.getRenderPixel(evt, mapSize);

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(tl[0], tl[1]);
            ctx.lineTo(bl[0], bl[1]);
            ctx.lineTo(br[0], br[1]);
            ctx.lineTo(tr[0], tr[1]);
            ctx.closePath();
            ctx.clip();

        });

        this._evtHandlerPos = this._layer.on('postrender', function (evt) {
            var ctx = evt.context;
            ctx.restore();
        });
    }

    _unregisterLayerEvents() {

        this._layer.un('prerender', this._evtHandlerPre.listener);
        this._layer.on('postrender', this._evtHandlerPos.listener);

        this._evtHandlerPre = null;
        this._evtHandlerPos = null;

    }


    activate() {

        this.map.closeAllTools();

        super.activate();
        this.map.hideDisabledControls();
        this._listLayers();
        this._getLayer();
        this._registerLayerEvents();
        this.map.ol.render();

     
        this._layersSelectElm.style.display = 'block';
        this._rangeElm.style.display = 'block';
        this._decoratorElm.style.display = 'block';

    }

    deactivate() {

        this._unregisterLayerEvents();
        this.map.showDisabledControls();
        super.deactivate();

        this._layersSelectElm.style.display = 'none';
        this._rangeElm.style.display = 'none';
        this._decoratorElm.style.display = 'none';
        this.map.ol.render();
    }

}

export { LayerSwipe };