import { GeoWidget } from '../core/GeoWidget';

class CreateLineString extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Criar Linha';
        config.title = config.title || 'Criar Simples';
        config.class = config.class || 'gb-draw-line';
        super(config);

        this._externalSource = config.source;
        this._firstPointFeature = new ol.Feature();
        this._lastPointFeature = new ol.Feature();
        this._auxFeature = new ol.Feature();
        this._lineFeature = new ol.Feature();
        this.ui = this._getUiTemplate();

    }

    initialize() {

        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();
            this._initInternalLayer();

        });

    }

    _initInternalLayer() {



        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });



        this._layer.getSource().addFeature(this._firstPointFeature);
        this._layer.getSource().addFeature(this._lastPointFeature);
        this._layer.getSource().addFeature(this._lineFeature);

        this._layer.setZIndex(99999);
        map.ol.addLayer(this._layer);

    }

    _getUiTemplate() {

        return `

            <div class="d-none" id="w-panel-${this.id}" style="position: absolute; width: 100%; height: 90%; background: rgba(255,255,255,.95); z-index:9999;">
                <div style="margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                    <h6>Clique sobre o mapa ou <a id="w-cancel-${this.id}" class="" href="#">cancelar</a></h6>
                </div>
            </div>

            <div class='m-0 p-3'>
                <h6>Início</h6>
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a id="ini-nav-abs-${this.id}" class="nav-link active" href="#">Absoluta</a>
                    </li>
                    <li class="nav-item">
                        <a id="ini-nav-map-${this.id}" class="nav-link" href="#">A partir do mapa</a>
                    </li>
                </ul>
                
                <div class='row p-0 m-0'>
                    
                    <div id="ini-cnt-abs-${this.id}" class='col pt-3 pb-3 border border-top-0'>
                        <h6 class="card-subtitle mb-2 text-muted">Coordenadas</h6>
                        <div class="row">
                            <div class="col">
                                <input id="ini-cnt-abs-x-${this.id}" type="number" class="form-control form-control-sm" placeholder="X inicial">
                            </div>
                            <div class="col">
                                <input id="ini-cnt-abs-y-${this.id}" type="number" class="form-control form-control-sm" placeholder="Y inicial">
                            </div>
                            <div class="col">
                                <select id="ini-cnt-abs-srid-${this.id}" class="form-control form-control-sm">
                                    <option value="" selected>Selecione SRS</option>
                                    <option value="EPSG:4326">WGS-84</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <button id="ini-cnt-abs-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm" disabled>Adicionar</button>
                            </div>
                        </div>      
                    </div>

                    <div id="ini-cnt-map-${this.id}" class='col pt-3 pb-3 border border-top-0 d-none'>
                        
                        <h6 class="card-subtitle mb-2 text-muted">A partir do mapa</h6>
                        <div class="row">
                            <div class="col">
                                <button id="ini-cnt-map-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm">Obter coordenadas</button>
                            </div>
                            <div class="col">
                                <p id="ini-cnt-map-coords-${this.id}" class="float-right"></p>
                            </div>
                        </div>      
                    </div>
                </div>

                <h6 class="pt-3">Final</h6>
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a id="fim-nav-abs-${this.id}" class="nav-link active" href="#">Absoluta</a>
                    </li>
                    <li class="nav-item">
                        <a id="fim-nav-map-${this.id}" class="nav-link" href="#">A partir do mapa</a>
                    </li>
                    <li class="nav-item">
                        <a id="fim-nav-rel-${this.id}" class="nav-link" href="#">Relativo</a>
                    </li>
                    <li class="nav-item">
                        <a id="fim-nav-dir-${this.id}" class="nav-link" href="#">Azimute e Distância</a>
                    </li>
                    <li class="nav-item">
                        <a id="fim-nav-dis-${this.id}" class="nav-link" href="#">Direção (mapa) e Distância</a>
                    </li>
                </ul>
                <div class='row p-0 m-0'>
                    <div id="fim-cnt-abs-${this.id}" class='col pt-3 pb-3 border border-top-0'>
                        <h6 class="card-subtitle mb-2 text-muted">Coordenadas</h6>
                        <div class="row">
                            <div class="col">
                                <input id="fim-cnt-abs-x-${this.id}" type="number" class="form-control form-control-sm" placeholder="X final">
                            </div>
                            <div class="col">
                                <input id="fim-cnt-abs-y-${this.id}" type="number" class="form-control form-control-sm" placeholder="Y final">
                            </div>
                            <div class="col">
                                <select  id="fim-cnt-abs-srid-${this.id}" class="form-control form-control-sm">
                                <option value="" selected>Selecione SRS</option>
                                <option value="EPSG:4326">WGS-84</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <button id="fim-cnt-abs-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm" disabled>Adicionar</button>
                            </div>
                        </div>
                    </div>
                    <div id="fim-cnt-map-${this.id}" class='col pt-3 pb-3 border border-top-0 d-none'>
                        <h6 class="card-subtitle mb-2 text-muted">A partir do mapa</h6>
                        <div class="row">
                            <div class="col">
                                <button id="fim-cnt-map-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm">Obter coordenadas</button>
                            </div>
                            <div class="col">
                                <p id="fim-cnt-map-coords-${this.id}" class="float-right"></p>
                            </div>
                        </div>
                    
                    </div>
                    <div id="fim-cnt-rel-${this.id}" class='col pt-3 pb-3 border border-top-0 d-none'>
                        <h6 class="card-subtitle mb-2 text-muted">Variações (m)</h6>
                        <div class="row">
                            <div class="col">
                                <input id="fim-cnt-rel-x-${this.id}" type="number" class="form-control form-control-sm" placeholder="ΔX">
                            </div>
                            <div class="col">
                                <input id="fim-cnt-rel-y-${this.id}" type="number" class="form-control form-control-sm" placeholder="ΔY">
                            </div>
                            <div class="col-2">
                                <button id="fim-cnt-rel-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm" disabled>Adicionar</button>
                            </div>
                        </div>
                    </div>
                    <div id="fim-cnt-dir-${this.id}" class='col pt-3 pb-3 border border-top-0 d-none'>
                        <h6 class="card-subtitle mb-2 text-muted">Azimute e Distância</h6>
                        <div class="row">
                            <div class="col">
                                <input id="fim-cnt-dir-dir-${this.id}" type="number" class="form-control form-control-sm" placeholder="Azimute">
                            </div>
                            <div class="col">
                                <input id="fim-cnt-dir-dis-${this.id}" type="number" class="form-control form-control-sm" placeholder="Distância">
                            </div>
                            <div class="col-2">
                                <button id="fim-cnt-dir-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm" disabled>Adicionar</button>
                            </div>
                              
                        </div>
                    </div>
                    <div id="fim-cnt-dis-${this.id}" class='col pt-3 pb-3 border border-top-0  d-none '>
                        <h6 class="card-subtitle mb-2 text-muted">Distância, direção relativa e direção base</h6>
                        <div class="row">
                            <div class="col">
                                <input id="fim-cnt-dis-dis-${this.id}" type="number" class="form-control form-control-sm" placeholder="Distância">
                            </div>
                            <div class="col">
                                <input id="fim-cnt-dis-dir-${this.id}" type="number" class="form-control form-control-sm" placeholder="Direção relativa">
                            </div>
                            <div class="col-4">
                                <button id="fim-cnt-dis-btn-${this.id}" type="submit" class="btn btn-primary form-control-sm btn-block" disabled>Obter direção base</button>
                            </div>
                             
                        </div>
                    </div>
                </div>

                <div class="row pt-3">
                    <div class="col">
                        <button id="btn-clear-${this.id}" type="button" class="btn btn-light m-3">Limpar</button>
                    </div>
                    <div class="col">
                        <button id="btn-proc-${this.id}" type="button" class="btn btn-primary float-right m-3" disabled>Inserir Linha</button>
                    </div>
                </div>
            </div>   
        `;

    }

    _createPointGeom(x, y, srid) {

        let coords = [parseFloat(x), parseFloat(y)];
        if (srid) coords = proj4(srid, this.map.srid, coords);
        return new ol.geom.Point(coords);

    }

    _createLine() {

        let p1 = this._firstPointFeature.getGeometry().getCoordinates().slice();
        let p2 = this._lastPointFeature.getGeometry().getCoordinates().slice();

        this._firstPointFeature.setGeometry(null);
        this._lastPointFeature.setGeometry(null);

        let geom = new ol.geom.LineString([p1, p2]);
        this._lineFeature.setGeometry(geom);

        let feature = new ol.Feature();
        feature.setGeometry(geom);

        this._externalSource.addFeature(feature);
        this._layer.getSource().clear();

    }

    _clearLine() {

        this._lineFeature.setGeometry(null);
        this._lastPointFeature.setGeometry(null);
        this._firstPointFeature.setGeometry(null);

        this._iniCntAbsXElm.value = '';
        this._iniCntAbsYElm.value = '';
        this._iniCntAbsSridElm.value = '';
        this._iniCntMapCoordsElm.innerHTML = '';
        this._fimCntAbsXElm.value = '';
        this._fimCntAbsYElm.value = '';
        this._fimCntAbsSridElm.value = '';
        this._fimCntMapCoordsElm.innerHTML = '';
        this._fimCntRelXElm.value = '';
        this._fimCntRelYElm.value = '';
        this._fimCntDirDirElm.value = '';
        this._fimCntDirBtnElm.value = '';
        this._fimCntDirDisElm.value = '';
        this._fimCntDisDisElm.value = '';


    }

    _registerElements() {

        this._iniNavAbsElm = document.getElementById(`ini-nav-abs-${this.id}`);
        this._iniNavMapElm = document.getElementById(`ini-nav-map-${this.id}`);
        this._iniCntAbsElm = document.getElementById(`ini-cnt-abs-${this.id}`);
        this._iniCntAbsXElm = document.getElementById(`ini-cnt-abs-x-${this.id}`);
        this._iniCntAbsYElm = document.getElementById(`ini-cnt-abs-y-${this.id}`);
        this._iniCntAbsBtn = document.getElementById(`ini-cnt-abs-btn-${this.id}`);
        this._iniCntAbsSridElm = document.getElementById(`ini-cnt-abs-srid-${this.id}`);
        this._iniCntMapElm = document.getElementById(`ini-cnt-map-${this.id}`);
        this._iniCntMapBtnElm = document.getElementById(`ini-cnt-map-btn-${this.id}`);
        this._iniCntMapCoordsElm = document.getElementById(`ini-cnt-map-coords-${this.id}`);
        this._fimNavAbsElm = document.getElementById(`fim-nav-abs-${this.id}`);
        this._fimNavMapElm = document.getElementById(`fim-nav-map-${this.id}`);
        this._fimNavRelElm = document.getElementById(`fim-nav-rel-${this.id}`);
        this._fimNavDirElm = document.getElementById(`fim-nav-dir-${this.id}`);
        this._fimNavDisElm = document.getElementById(`fim-nav-dis-${this.id}`);
        this._fimCntAbsElm = document.getElementById(`fim-cnt-abs-${this.id}`);
        this._fimCntAbsXElm = document.getElementById(`fim-cnt-abs-x-${this.id}`);
        this._fimCntAbsYElm = document.getElementById(`fim-cnt-abs-y-${this.id}`);
        this._fimCntAbsSridElm = document.getElementById(`fim-cnt-abs-srid-${this.id}`);
        this._fimCntAbsBtnElm = document.getElementById(`fim-cnt-abs-btn-${this.id}`);
        this._fimCntMapCoordsElm = document.getElementById(`fim-cnt-map-coords-${this.id}`);
        this._fimCntMapElm = document.getElementById(`fim-cnt-map-${this.id}`);
        this._fimCntMapBtnElm = document.getElementById(`fim-cnt-map-btn-${this.id}`);
        this._fimCntRelElm = document.getElementById(`fim-cnt-rel-${this.id}`);
        this._fimCntRelXElm = document.getElementById(`fim-cnt-rel-x-${this.id}`);
        this._fimCntRelYElm = document.getElementById(`fim-cnt-rel-y-${this.id}`);
        this._fimCntRelBtnElm = document.getElementById(`fim-cnt-rel-btn-${this.id}`);
        this._fimCntDirElm = document.getElementById(`fim-cnt-dir-${this.id}`);
        this._fimCntDirDirElm = document.getElementById(`fim-cnt-dir-dir-${this.id}`);
        this._fimCntDirBtnElm = document.getElementById(`fim-cnt-dir-btn-${this.id}`);
        this._fimCntDirDisElm = document.getElementById(`fim-cnt-dir-dis-${this.id}`);
        this._fimCntDisElm = document.getElementById(`fim-cnt-dis-${this.id}`);
        this._fimCntDisDisElm = document.getElementById(`fim-cnt-dis-dis-${this.id}`);
        this._fimCntDisDirElm = document.getElementById(`fim-cnt-dis-dir-${this.id}`);
        this._fimCntDisBtnElm = document.getElementById(`fim-cnt-dis-btn-${this.id}`);
        this._btnProcElm = document.getElementById(`btn-proc-${this.id}`);
        this._btnClearElm = document.getElementById(`btn-clear-${this.id}`);
        this._cancelPointElm = document.getElementById(`w-cancel-${this.id}`);
        this._waitPanelElm = document.getElementById(`w-panel-${this.id}`);

    }

    _registerEvents() {

        // Navs
        this._iniNavAbsElm.addEventListener('click', () => {

            this._iniNavAbsElm.classList.add('active');
            this._iniNavMapElm.classList.remove('active');

            this._iniCntAbsElm.classList.remove('d-none');
            this._iniCntMapElm.classList.add('d-none');

        });

        this._iniNavMapElm.addEventListener('click', () => {

            this._iniNavAbsElm.classList.remove('active');
            this._iniNavMapElm.classList.add('active');

            this._iniCntAbsElm.classList.add('d-none');
            this._iniCntMapElm.classList.remove('d-none');

        });

        this._fimNavAbsElm.addEventListener('click', () => {

            this._fimNavAbsElm.classList.add('active');
            this._fimNavMapElm.classList.remove('active');
            this._fimNavRelElm.classList.remove('active');
            this._fimNavDirElm.classList.remove('active');
            this._fimNavDisElm.classList.remove('active');

            this._fimCntAbsElm.classList.remove('d-none');
            this._fimCntMapElm.classList.add('d-none');
            this._fimCntRelElm.classList.add('d-none');
            this._fimCntDirElm.classList.add('d-none');
            this._fimCntDisElm.classList.add('d-none');

        });

        this._fimNavMapElm.addEventListener('click', () => {

            this._fimNavAbsElm.classList.remove('active');
            this._fimNavMapElm.classList.add('active');
            this._fimNavRelElm.classList.remove('active');
            this._fimNavDirElm.classList.remove('active');
            this._fimNavDisElm.classList.remove('active');

            this._fimCntAbsElm.classList.add('d-none');
            this._fimCntMapElm.classList.remove('d-none');
            this._fimCntRelElm.classList.add('d-none');
            this._fimCntDirElm.classList.add('d-none');
            this._fimCntDisElm.classList.add('d-none');

        });
        this._fimNavRelElm.addEventListener('click', () => {

            this._fimNavAbsElm.classList.remove('active');
            this._fimNavMapElm.classList.remove('active');
            this._fimNavRelElm.classList.add('active');
            this._fimNavDirElm.classList.remove('active');
            this._fimNavDisElm.classList.remove('active');

            this._fimCntAbsElm.classList.add('d-none');
            this._fimCntMapElm.classList.add('d-none');
            this._fimCntRelElm.classList.remove('d-none');
            this._fimCntDirElm.classList.add('d-none');
            this._fimCntDisElm.classList.add('d-none');

        });
        this._fimNavDirElm.addEventListener('click', () => {

            this._fimNavAbsElm.classList.remove('active');
            this._fimNavMapElm.classList.remove('active');
            this._fimNavRelElm.classList.remove('active');
            this._fimNavDirElm.classList.add('active');
            this._fimNavDisElm.classList.remove('active');

            this._fimCntAbsElm.classList.add('d-none');
            this._fimCntMapElm.classList.add('d-none');
            this._fimCntRelElm.classList.add('d-none');
            this._fimCntDirElm.classList.remove('d-none');
            this._fimCntDisElm.classList.add('d-none');

        });
        this._fimNavDisElm.addEventListener('click', () => {

            this._fimNavAbsElm.classList.remove('active');
            this._fimNavMapElm.classList.remove('active');
            this._fimNavRelElm.classList.remove('active');
            this._fimNavDirElm.classList.remove('active');
            this._fimNavDisElm.classList.add('active');

            this._fimCntAbsElm.classList.add('d-none');
            this._fimCntMapElm.classList.add('d-none');
            this._fimCntRelElm.classList.add('d-none');
            this._fimCntDirElm.classList.add('d-none');
            this._fimCntDisElm.classList.remove('d-none');

        });

        // Inputs
        this._iniCntAbsXElm.addEventListener('input', () => {

            this._iniCntAbsBtn.disabled = !isFullFillValues([this._iniCntAbsXElm, this._iniCntAbsYElm, this._iniCntAbsSridElm]);

        });

        this._iniCntAbsYElm.addEventListener('input', () => {

            this._iniCntAbsBtn.disabled = !isFullFillValues([this._iniCntAbsXElm, this._iniCntAbsYElm, this._iniCntAbsSridElm]);

        });

        this._iniCntAbsSridElm.addEventListener('change', () => {

            this._iniCntAbsBtn.disabled = !isFullFillValues([this._iniCntAbsXElm, this._iniCntAbsYElm, this._iniCntAbsSridElm]);

        });

        this._fimCntAbsXElm.addEventListener('input', () => {

            this._fimCntAbsBtnElm.disabled = !isFullFillValues([this._fimCntAbsXElm, this._fimCntAbsYElm, this._fimCntAbsSridElm]);

        });

        this._fimCntAbsYElm.addEventListener('input', () => {

            this._fimCntAbsBtnElm.disabled = !isFullFillValues([this._fimCntAbsXElm, this._fimCntAbsYElm, this._fimCntAbsSridElm]);

        });

        this._fimCntAbsSridElm.addEventListener('change', () => {

            this._fimCntAbsBtnElm.disabled = !isFullFillValues([this._fimCntAbsXElm, this._fimCntAbsYElm, this._fimCntAbsSridElm]);

        });

        this._fimCntRelXElm.addEventListener('input', () => {

            this._fimCntRelBtnElm.disabled = !isFullFillValues([this._fimCntRelXElm, this._fimCntRelYElm]);
            this._fimCntRelBtnElm.disabled = this._firstPointFeature.getGeometry() ? false : true;

        });

        this._fimCntRelYElm.addEventListener('input', () => {

            this._fimCntRelBtnElm.disabled = !isFullFillValues([this._fimCntRelXElm, this._fimCntRelYElm]);
            this._fimCntRelBtnElm.disabled = this._firstPointFeature.getGeometry() ? false : true;

        });

        this._fimCntDirDirElm.addEventListener('input', () => {

            this._fimCntDirBtnElm.disabled = !isFullFillValues([this._fimCntDirDirElm, this._fimCntDirDisElm]);
            this._fimCntDirBtnElm.disabled = this._firstPointFeature.getGeometry() ? false : true;

        });

        this._fimCntDirDisElm.addEventListener('input', () => {

            this._fimCntDirBtnElm.disabled = !isFullFillValues([this._fimCntDirDirElm, this._fimCntDirDisElm]);
            this._fimCntDirBtnElm.disabled = this._firstPointFeature.getGeometry() ? false : true;

        });

        this._fimCntDisDisElm.addEventListener('input', () => {

            this._fimCntDisBtnElm.disabled = !isFullFillValues([this._fimCntDisDisElm]);
            this._fimCntDirBtnElm.disabled = this._firstPointFeature.getGeometry() ? false : true;

        });

        this._fimCntDisDirElm.addEventListener('input', () => {

            if (this._firstPointFeature.getGeometry() && this._lastPointFeature.getGeometry()) {

                let dis = parseFloat(this._fimCntDisDisElm.value);
                let relDir = this._fimCntDisDirElm.value;
                relDir = relDir != '' ? (parseFloat(relDir) / 180) * Math.PI : 0;

                let p1 = this._firstPointFeature.getGeometry().getCoordinates().slice();
                let p2 = this._auxFeature.getGeometry().getCoordinates().slice();

                let dir = Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);
                dir = dir + relDir;
                dir = dir < 0 ? dir + Math.PI * 2 : dir;

                p2[0] = p1[0] + dis * Math.sin(dir);
                p2[1] = p1[1] + dis * Math.cos(dir);
                let geom = this._createPointGeom(p2[0], p2[1], 'EPSG:3857');
                this._lastPointFeature.setGeometry(geom);

                this._waitPanelElm.classList.add('d-none');
                handleProcBtn();

            }


        });

        // Btns
        this._iniCntAbsBtn.addEventListener('click', () => {

            let geom = this._createPointGeom(this._iniCntAbsXElm.value, this._iniCntAbsYElm.value, this._iniCntAbsSridElm.value);
            this._firstPointFeature.setGeometry(geom);
            this.map.fitTo(geom, 'openlayers');
            handleProcBtn();

        });

        this._cancelPointElm.addEventListener('click', () => {

            this.map.toolbox.draw.stopDrawing();
            this._waitPanelElm.classList.add('d-none');
            handleProcBtn();

        });

        this._iniCntMapBtnElm.addEventListener('click', () => {

            this._waitPanelElm.classList.remove('d-none');
            this.map.toolbox.draw.getPoint().then((p) => {
                this._firstPointFeature.setGeometry(p.getGeometry());
                this._waitPanelElm.classList.add('d-none');

                this._iniCntMapCoordsElm.innerHTML = p.getGeometry()
                    .getCoordinates()
                    .toString()
                    .replace(',', ' ')
                    .replace('.', ',')
                    .replace('.', ',');

                handleProcBtn();
            });



        });

        this._fimCntAbsBtnElm.addEventListener('click', () => {

            let geom = this._createPointGeom(this._fimCntAbsXElm.value, this._fimCntAbsYElm.value, this._fimCntAbsSridElm.value);
            this._lastPointFeature.setGeometry(geom);
            this.map.fitTo(geom, 'openlayers');
            handleProcBtn();

        });

        this._fimCntMapBtnElm.addEventListener('click', () => {

            this._waitPanelElm.classList.remove('d-none');
            this.map.toolbox.draw.getPoint().then((p) => {
                this._lastPointFeature.setGeometry(p.getGeometry());
                this._waitPanelElm.classList.add('d-none');

                this._fimCntMapCoordsElm.innerHTML = p.getGeometry()
                    .getCoordinates()
                    .toString()
                    .replace(',', ' ')
                    .replace('.', ',')
                    .replace('.', ',');

                handleProcBtn();
            });



        });

        this._fimCntRelBtnElm.addEventListener('click', () => {

            let dx = parseFloat(this._fimCntRelXElm.value);
            let dy = parseFloat(this._fimCntRelYElm.value);
            let coords = this._firstPointFeature.getGeometry().getCoordinates().slice();
            coords = proj4(this.map.srid, 'EPSG:3857', coords);
            coords[0] += dx;
            coords[1] += dy;
            let geom = this._createPointGeom(coords[0], coords[1], 'EPSG:3857');
            this._lastPointFeature.setGeometry(geom);
            handleProcBtn();

        });

        this._fimCntDirBtnElm.addEventListener('click', () => {

            let dir = parseFloat(this._fimCntDirDirElm.value);
            let dis = parseFloat(this._fimCntDirDisElm.value);
            let coords = this._firstPointFeature.getGeometry().getCoordinates().slice();
            coords = proj4(this.map.srid, 'EPSG:3857', coords);
            coords[0] += dis * Math.sin((dir / 180) * Math.PI);
            coords[1] += dis * Math.cos((dir / 180) * Math.PI);
            let geom = this._createPointGeom(coords[0], coords[1], 'EPSG:3857');
            this._lastPointFeature.setGeometry(geom);
            handleProcBtn();

        });

        this._fimCntDisBtnElm.addEventListener('click', () => {

            let dis = parseFloat(this._fimCntDisDisElm.value);
            let relDir = this._fimCntDisDirElm.value;
            relDir = relDir != '' ? (parseFloat(relDir) / 180) * Math.PI : 0;


            this.map.toolbox.draw.getPoint().then((p) => {

                let p1 = this._firstPointFeature.getGeometry().getCoordinates();
                let p2 = p.getGeometry().getCoordinates();

                let dir = Math.atan2(p2[0] - p1[0], p2[1] - p1[1]);
                dir = dir < 0 ? dir + Math.PI * 2 : dir;
                dir += relDir;

                p2[0] = p1[0] + dis * Math.sin(dir);
                p2[1] = p1[1] + dis * Math.cos(dir);
                let geom = this._createPointGeom(p2[0], p2[1], 'EPSG:3857');
                this._lastPointFeature.setGeometry(geom);
                this._auxFeature.setGeometry(geom);

                this._waitPanelElm.classList.add('d-none');
                handleProcBtn();

            });


        });

        this._btnProcElm.addEventListener('click', () => {

            this._createLine();

        });

        this._btnClearElm.addEventListener('click', () => {

            this._clearLine();

        });


        // fncs
        let isFullFillValues = (elms) => {

            let missing = false;

            for (let i = 0; i < elms.length; i++) {

                if (elms[i].value == '') {

                    missing = true;

                }

            }

            return !missing;
        }

        let handleProcBtn = () => {

            let hasFirstPoint = Boolean(this._firstPointFeature.getGeometry());
            let hasLastPoint = Boolean(this._lastPointFeature.getGeometry());

            this._btnProcElm.disabled = !(hasFirstPoint && hasLastPoint);

        }
    }

    activate() {

        this.show();

    }

    deactivate() {

        this.hide();

    }

}

export { CreateLineString };