import { GeoWidget } from '../core/GeoWidget';

class ParallelCopy extends GeoWidget {

    constructor(config) {

        config = config || {};
        config.tip = config.tip || 'Cópia';
        config.title = config.title || 'Cópia';
        config.class = config.class || 'gb-rotate-geom';
        super(config);
        this._select = null;
        this._selectedFeatures = [];
        this._selectedSides = [];
        this._copiedFeatures = [];
        this._layer = null;
        this._externalSource = config.source;
        this.ui = `
            <div class="p-3">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Selecione a feição a ser copiada</label><br>
                            <button id="select-btn-${this.id}" type="button" class="btn btn-primary">Selecionar feição</button>
                            <small id="count-feature-text-${this.id}" class="pl-2"> Nenhuma feição selecionada.</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Modo da cópia</label>
                            <div class="form-check">
                                <input id="parallel-check-${this.id}" class="form-check-input" type="radio" name="copy-mode" value="centroid">
                                <label class="form-check-label" for="parallel-check-${this.id}">
                                    Paralelo a um lado
                                </label>
                            </div>
                            <div class="form-check">
                                <input id="direction-check-${this.id}" class="form-check-input" type="radio" name="copy-mode"  value="first">
                                <label class="form-check-label" for="direction-check-${this.id}">
                                    Em uma direção
                                </label>
                            </div>
                        </div>
                    </div>
                    <div id="group-feature-side-${this.id}" class="col d-none">
                        <div class="form-group">
                            <label>Selecione o lado de referência</label><br>
                            <button id="select-side-btn-${this.id}" type="button" class="btn btn-primary">Selecionar lado</button>
                            <br>
                            <small id="count-side-text-${this.id}" class="pl-2"> Nenhum lado selecionado.</small>
                        </div>
                    </div>
                    <div id="group-dir-${this.id}" class="col d-none">
                        <div class="form-group">
                            <label>Direção da cópia (Azimute)</label>
                            <input id="az-input-${this.id}" type="number" class="form-control" placeholder="Direção (Azimute)" value="0" step="0.1" required>
                        </div>
                    </div>
                </div>
                <div id="group-side-${this.id}" class="row d-none">
                    <div class="col">
                        <div class="form-group">
                            <label>Lado para copiar</label>
                            <div class="form-check">
                                <input id="right-check-${this.id}" class="form-check-input" type="radio" name="rotate-point" value="centroid" checked>
                                <label class="form-check-label" for="centroid-check-${this.id}">
                                    Direito
                                </label>
                            </div>
                            <div class="form-check">
                                <input id="left-check-${this.id}" class="form-check-input" type="radio" name="rotate-point"  value="first">
                                <label class="form-check-label" for="left-check-${this.id}">
                                    Esquerdo
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Distância entre cópias (m)</label>
                            <input id="dist-input-${this.id}" type="number" class="form-control" placeholder="Distância" value="0" step="0.1">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Número de cópias</label>
                            <input id="num-input-${this.id}" type="number" class="form-control" placeholder="Número de cópias" value="1" step="1" min="1">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <br>
                            <button id="clear-btn-${this.id}" type="button" class="btn btn-light btn-block">Limpar</button>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <br>
                            <button id="run-btn-${this.id}" type="button" class="btn btn-primary btn-block" disabled>Copiar</button>
                        </div>
                    </div>
                </div>
            </div>`;
    }


    initialize() {

        this._initLayer();
        this._initInteractions();
        this.on('ready', () => {

            this._registerElements();
            this._registerEvents();

        });

        if (!this._externalSource) console.error('Set a source to ParallelCopy.')

    };

    _initInteractions() {


        this._featureSelect = new ol.interaction.Select();
        this._sideSelect = new ol.interaction.Select({
            layers: [this._sideLayer]
        });


        this._featureSelect.on('select', (e) => {

            this._selectedFeatures = e.target.getFeatures().getArray();
            this._sideFeatures = this._generateSideFeatures(this._selectedFeatures);
            this._sideLayer.getSource().addFeatures(this._sideFeatures);
            this.map.ol.removeInteraction(this._featureSelect);
            this._handleCountText();
            this._runBtn.disabled = true;
            this._selectSideBtn.disabled = false;

        });

        this._sideSelect.on('select', (e) => {

            this._selectedSides = e.target.getFeatures().getArray();
            this._handleCountText();
            this._runBtn.disabled = (this._selectedSides == 0);

        });

    }

    _initLayer() {

        this._layer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 0, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#f0f',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#f0f'
                    })
                })
            })
        });

        this._sideLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(0, 255, 255, 0.2)'
                }),
                stroke: new ol.style.Stroke({
                    color: '#0ff',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: '#0ff'
                    })
                })
            })
        });

        this._layer.setZIndex(9998);
        this._sideLayer.setZIndex(99999);

        this.map.ol.addLayer(this._layer);
        this.map.ol.addLayer(this._sideLayer);

    }

    _registerElements() {

        this._selectBtn = document.getElementById(`select-btn-${this.id}`);
        this._countFeatureText = document.getElementById(`count-feature-text-${this.id}`);
        this._parallelCheck = document.getElementById(`parallel-check-${this.id}`);
        this._directionCheck = document.getElementById(`direction-check-${this.id}`);
        this._groupSide = document.getElementById(`group-side-${this.id}`);
        this._groupFeatureSide = document.getElementById(`group-feature-side-${this.id}`);
        this._selectSideBtn = document.getElementById(`select-side-btn-${this.id}`);
        this._countSideText = document.getElementById(`count-side-text-${this.id}`);
        this._groupDir = document.getElementById(`group-dir-${this.id}`);
        this._azInput = document.getElementById(`az-input-${this.id}`);
        this._rightCheck = document.getElementById(`right-check-${this.id}`);
        this._leftCheck = document.getElementById(`left-check-${this.id}`);
        this._distInput = document.getElementById(`dist-input-${this.id}`);
        this._numInput = document.getElementById(`num-input-${this.id}`);
        this._clearBtn = document.getElementById(`clear-btn-${this.id}`);
        this._runBtn = document.getElementById(`run-btn-${this.id}`);

    }

    _registerEvents() {

        this._selectBtn.addEventListener('click', () => {

            this._selectFeaures();

        });

        this._parallelCheck.addEventListener('click', () => {

            this._groupSide.classList.remove('d-none');
            this._groupFeatureSide.classList.remove('d-none');
            this._groupDir.classList.add('d-none');

            this._handleCountText();
            this._runBtn.disabled = (this._selectedSides == 0);

        });

        this._directionCheck.addEventListener('click', () => {

            this._groupSide.classList.add('d-none');
            this._groupFeatureSide.classList.add('d-none');
            this._groupDir.classList.remove('d-none');

            this._runBtn.disabled = false;

        });

        this._selectSideBtn.addEventListener('click', () => {

            this._selectSide();

        });

        this._azInput.addEventListener('input', () => {

            this._copyFeatures();

        });

        this._rightCheck.addEventListener('click', () => {

            this._copyFeatures();

        });

        this._leftCheck.addEventListener('click', () => {

            this._copyFeatures();

        });

        this._distInput.addEventListener('input', () => {

            this._copyFeatures();

        });

        this._numInput.addEventListener('input', () => {

            this._copyFeatures();

        });

        this._clearBtn.addEventListener('click', () => {

            this._clear();

        });

        this._runBtn.addEventListener('click', () => {

            this._persist();

        });

    }

    _generateSideFeatures(features) {

        let gf = new ol.format.GeoJSON();
        let sides = [];

        for (let i = 0; i < features.length; i++) {

            let geom = gf.writeGeometryObject(this._selectedFeatures[i].getGeometry());

            turf.segmentEach(geom, function (currentSegment) {

                let segmentFeature = gf.readFeatureFromObject(currentSegment);
                sides.push(segmentFeature);

            });

        }

        return sides;

    }

    _handleCountText() {

        let count = this._selectedFeatures.length;
        let sideCount = this._sideFeatures.length;
        let selSideCount = this._selectedSides.length;

        switch (count) {
            case 0:
                this._countFeatureText.innerHTML = sideCount == 0 ? 'Nenhuma feição selecionada.' : '';
                break;
            case 1:
                this._countFeatureText.innerHTML = 'Feição selecionada.'
                break;
            default:
                this._countFeatureText.innerHTML = count + ' feições selecionadas.'
                break;
        }

        switch (selSideCount) {
            case 0:
                this._countSideText.innerHTML = 'Nenhum lado selecionado.'
                break;
            case 1:
                this._countSideText.innerHTML = 'Lado selecionado.'
                break;
            default:
                this._countSideText.innerHTML = count + ' lados selecionados.'
                break;
        }

    }

    _selectFeaures() {

        this._selectBtn.disabled = true;
        this.map.ol.addInteraction(this._featureSelect);

    }

    _selectSide() {

        this._selectBtn.disabled = true;
        this._selectSideBtn.disabled = true;
        this.map.ol.addInteraction(this._sideSelect);

    }

    _clear() {

        this._featureSelect.getFeatures().clear();
        this._sideSelect.getFeatures().clear();
        this._selectedFeatures = [];
        this._sideFeatures = [];
        this._sideLayer.getSource().clear();
        this._layer.getSource().clear();
        this.map.ol.removeInteraction(this._sideSelect);

        this._selectBtn.disabled = false;
        this._selectSideBtn.disabled = true;
        this._parallelCheck.checked = false;
        this._directionCheck.checked = false;
        this._groupSide.classList.add('d-none');
        this._groupFeatureSide.classList.add('d-none');
        this._groupDir.classList.add('d-none');
        this._azInput.value = 0;
        this._rightCheck.checked = true;
        this._leftCheck.checked = false;
        this._distInput.value = 0;
        this._numInput.value = 1;
        this._runBtn.disabled = true;

        this._handleCountText();

    }

    _copyFeatures() {

        let gf = new ol.format.GeoJSON();
        let dist = Number(this._distInput.value);
        let reverse = this._leftCheck.checked ? -1 : 1;
        let az = Number(this._azInput.value);
        let copyCount = Number(this._numInput.value);
        let opt = {
            units: 'meters'
        };
        this._layer.getSource().clear();

        for (let i = 0; i < this._selectedFeatures.length; i++) {

            if (this._selectedSides.length > 0) {

                let side = gf.writeGeometryObject(this._selectedSides[i].getGeometry());
                az = this._getAzimuth(side);
                this._azInput.value = az;
                az += 90;

            }

            this._selectedFeatures[i].getGeometry().transform(this.map.srid, 'EPSG:4326');

            for (let j = 0; j < copyCount; j++) {

                let geom = gf.writeGeometryObject(this._selectedFeatures[i].getGeometry());
                let copyGeom = turf.transformTranslate(geom, dist * (j + 1) * reverse, az, opt);
                let copyFeature = gf.readFeatureFromObject(copyGeom);
                copyFeature.getGeometry().transform('EPSG:4326', this.map.srid);
                this._layer.getSource().addFeature(copyFeature);
                this._copiedFeatures.push(copyFeature);

            }

            this._selectedFeatures[i].getGeometry().transform('EPSG:4326', this.map.srid);

        }

    }

    _getAzimuth(geom) {

        let coords = [];
        turf.coordEach(geom, function (currentCoord) {
            coords.push(currentCoord);
        });

        let bearing = turf.bearing(coords[0], coords[coords.length - 1]);
        return turf.bearingToAzimuth(bearing);

    }

    _persist() {


        if (this._externalSource) {

            let features = this._layer.getSource().getFeatures();
            this._externalSource.addFeatures(features);

        }

        this._clear();

    }

    activate() {

        super.activate();
        this.show();

    }

    deactivate() {

        this._clear();
        this.hide();
        super.deactivate();
    }
}

export { ParallelCopy };
