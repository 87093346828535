import { GeoWidget } from '../core/GeoWidget';

class ThematicOgc extends GeoWidget {

  constructor(config) {

    config = config || {};
    config.tip = config.tip || 'Mapa Temático';
    config.title = config.title || 'Gerar Mapa Temático';
    config.class = config.class || 'gb-layer-switcher-control';
    config.docked = config.docked || true;
    config.minWidth = config.minWidth || '300';
    config.minHeight = config.minHeight || '400';
    config.maxWidth = config.maxWidth || '400';
    config.maxHeight = config.maxHeight || '600';
    super(config);

    this._tables = null;
    this._classes = null;



  }

  initialize() {

    this.ui = this.builUi();

    this.on('ready', () => {

      this._registerEvents();

    });

  }

  _initLayer(){

    let d = this._getThematicDefinition();

    let format = new ol.format.GeoJSON();
    let vectorSource = new ol.source.Vector({
      format: format,
      url: function url(extent) {
          return window.location.origin + '/geowise/mapa/?estrategia=parcial&tabela=' + d.table + '&coluna=' + d.field + '&bbox=' + extent.join(',') + '&bboxSRID=3857&resultSRID=3857&maxFeatures=' + d.featureLimit;
      },
      strategy: function strategy(extent, resolution) {
          if (this.resolution && this.resolution != resolution) {
              this.clear();
          }
          this.resolution = resolution;
          return [extent];
      }
      
  });



  }

  builUi() {

    return `
          <div class="card">
            <div class="card-header" style="cursor: pointer;">
              Escolha o tema 
            </div>
            <div class="card-body">

              <div class="form-group">
                <label>Camada</label>
                <select class="form-control" id="to-layer">
                  <option value="-1">Selecione...</option>
                </select>
              </div>

              <div class="form-group">
                <label>Atributo</label>
                <select class="form-control" id="to-attribute">
                  <option value="-1">Selecione...</option>
                </select>
              </div>

              <div class="form-group">
                <label>Natureza do atributo</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="to-nature" id="to-qualitative" value="qualitative">
                  <label class="form-check-label" for="to-qualitative">
                    Qualitativo (abc)
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="to-nature" id="to-quantitative" value="quantitative">
                  <label class="form-check-label" for="to-quantitative">
                    Quantitativo (123)
                  </label>
                </div>
              </div>

            </div>
          </div>

          <div id="to-class-card" class="card d-none">
            <div class="card-header" style="cursor: pointer;">
              Classificação dos dados
            </div>
            <div class="card-body">

              <div class="form-group">
                <label>Método de classificação</label>
                <select class="form-control" id="to-method">
                  <option value="quantis">Quantis</option>'
                  <option value="int_iguais">Intervalos Iguais</option>'
                  <option value="quebras_naturais">Quebras Naturais</option>'
                  <option value="desvio_padrao">Desvio Padrão</option>'
                  <option value="int_definidos">Intervalos Definidos</option>'
                </select>
              </div>

              <div class="form-group">
                <label>Número de classes</label>
                <select class="form-control" id="to-class-number">
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>

              <div id="to-class-def-card" class="form-group d-none">
                <label>Definição das classes</label>
                <div id="to-class-def-list">
                  
                </div>
              </div>
              
            </div>
          </div>

          <div class="card">
            <div class="card-header" style="cursor: pointer;">
              Simbologia
            </div>
            <div class="card-body d-none">

              <div class="form-group">
                <label>Paleta de cores</label>
                <div id="to-color-pallet" class="w-100">
                  <div style="width: 25%; height: 20px; background: #333; float:left;"></div>
                  <div style="width: 25%; height: 20px; background: #777; float:left;"></div>
                  <div style="width: 25%; height: 20px; background: #aaa; float:left;"></div>
                  <div style="width: 25%; height: 20px; background: #ccc; float:left;"></div>
                  <br>
                  <small class="text-danger pt-3">Selecione a natureza do atributo!</small>
                </div>
                
              </div>
              
            </div>
          </div>

          <div class="card">
            <div class="card-header" style="cursor: pointer;">
              Opções
            </div>
            <div class="card-body d-none">

              <div class="form-group">
                <label>Estratégia</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="to-strategy" id="to-partial" value="partial" checked>
                  <label class="form-check-label" for="exampleRadios1">
                    Usar feições da visão atual
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="to-strategy" id="to-full" value="full">
                  <label class="form-check-label" for="to-full">
                    Usar todas as feições
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label>Limitar feições a</label>
                <input value="2000" type="number" class="form-control" id="to-max-features" placeholder="2000">
              </div>
              
            </div>
          </div>

          <div class="row pr-3 pl-3">
            <div class="col-5 p-3">
              <button id="to-clear-btn" type="button" class="btn btn-light btn-block">Limpar</button>
            </div>
            <div class="col-7 p-3">
              <button id="to-run-btn" type="button" class="btn btn-primary btn-block" disabled>Gerar</button>
            </div>
          </div>

         
      
            
        
        `;

  }

  _getData() {

    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {

      if (xhr.readyState == XMLHttpRequest.DONE) {

        if (xhr.status != 200) {

          alert("Erro interno. Contacte o administrador do sistema.");
          return;

        }

        let response = JSON.parse(xhr.responseText);

        if (response.success) {

          this._tables = response.data;
          this._listTables();

        } else {

          alert("Erro ao processar a requisição. Contacte o administrador do sistema.");

        }
      }
    }
    xhr.open('GET', 'response.json', true);
    xhr.send();

  }

  _getThematicDefinition(){

    let i = document.getElementById('to-layer').value;
    let j = document.getElementById('to-attribute').value;
    let table = `${this._tables[i].schema}.${this._tables[i].table}`;
    let field = this._tables[i].fields[j].column;
    let isQualitative = document.getElementById('to-qualitative').checked;
    let nature = isQualitative ? 'qualitativo' : 'quantitativo';
    let method = document.getElementById('to-method').value;
    let numClass = document.getElementById('to-class-number').value;

    return {
      table,
      field,
      nature,
      method,
      numClass
    }


  }

  _getClassification() {

    let d = this._getThematicDefinition();
    let query = `tabela=${d.table}&coluna=${d.field}&num_classes=${d.numClass}&metodo=${d.method}&natureza=${d.nature}`;

    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {

      if (xhr.readyState == XMLHttpRequest.DONE) {

        if (xhr.status != 200) {

          alert("Erro interno. Contacte o administrador do sistema.");
          return;

        }

        let response = JSON.parse(xhr.responseText);

        if (response) {

          this._classes = response;
          this._draw();

        } else {

          alert("Erro ao processar a requisição. Contacte o administrador do sistema.");

        }
      }
    }
    //xhr.open('GET', '/geowise/mapa/gerar_classificacao?' + query, true);
    xhr.open('GET', 'quantitativo.json?' + query, true);
    xhr.send();

  }

  _draw(){


  }

  _listTables() {

    let tablesElm = document.getElementById('to-layer');
    tablesElm.innerHTML = '<option value="-1">Selecione...</option>';
    for (let i = 0; i < this._tables.length; i++) {

      let option = document.createElement('option');
      option.value = i;
      option.innerHTML = this._tables[i].displayName;
      tablesElm.appendChild(option);

    }
  }

  _listAttributes() {

    let attributeElm = document.getElementById('to-attribute');
    attributeElm.innerHTML = '<option value="-1">Selecione...</option>';
    let i = document.getElementById('to-layer').value;
    for (let j = 0; j < this._tables[i].fields.length; j++) {

      let option = document.createElement('option');
      option.value = j;
      option.innerHTML = this._tables[i].fields[j].name;
      attributeElm.appendChild(option);

    }

  }

  _registerEvents() {

    let headers = this.ui.querySelectorAll('.card-header');
    for (let i = 0; i < headers.length; i++) {

      let header = headers[i];
      header.addEventListener('click', (evt) => {

        let elm = evt.srcElement.parentElement.getElementsByClassName('card-body')[0];
        let classes = elm.classList;

        if (classes.contains('d-none')) {

          classes.remove('d-none');

        } else {

          classes.add('d-none');

        }

      });

    }

    document.getElementById('to-layer').addEventListener('change', (evt) => {

      let layerIdx = evt.target.value;
      if (layerIdx >= 0) {

        this._listAttributes();

      } else {

        let attributeElm = document.getElementById('to-attribute');
        attributeElm.innerHTML = '<option value="-1">Selecione...</option>';
        document.getElementById('to-qualitative').checked = false;
        document.getElementById('to-quantitative').checked = false;

      }

      handleProcBtn();

    });

    document.getElementById('to-attribute').addEventListener('change', (evt) => {

      let layerIdx = document.getElementById('to-layer').value;
      let attrIdx = evt.target.value;

      if (layerIdx >= 0 && attrIdx >= 0) {

        let attribute_type = this._tables[layerIdx].fields[attrIdx].attribute_type;

        switch (attribute_type) {
          case 'String':
          case 'Opcoes':
          case 'ForeignKey':
            document.getElementById('to-qualitative').checked = true;
            break;
          case 'Integer':
          case 'Float':
            document.getElementById('to-quantitative').checked = true;
            break;
          default:
            document.getElementById('to-qualitative').checked = false;
            document.getElementById('to-quantitative').checked = false;
            break;
        }

      } else {

        document.getElementById('to-qualitative').checked = false;
        document.getElementById('to-quantitative').checked = false;

      }

      handleClassificationUi();
      handleProcBtn();

    });

    document.getElementById('to-quantitative').addEventListener('input', () => {

      handleClassificationUi();
      handleProcBtn();

    });

    document.getElementById('to-qualitative').addEventListener('input', () => {

      handleClassificationUi();
      handleProcBtn();

    });

    document.getElementById('to-method').addEventListener('change', () => {

      handleClasDef();

      if (document.getElementById('to-method').value != 'int_definidos') {

        document.getElementById('to-class-def-card').classList.add('d-none');

      } else {

        document.getElementById('to-class-def-card').classList.remove('d-none');

      }

      handleProcBtn();

    });

    document.getElementById('to-class-number').addEventListener('change', () => {

      handleClasDef();
      writeSymbology();
      handleProcBtn();

    });

    document.getElementById('to-run-btn').addEventListener('click', () => {

      this._getClassification();

    });

    // UI Handle Functions

    function handleClassificationUi() {

      if (document.getElementById('to-quantitative').checked) {

        document.getElementById('to-class-card').classList.remove('d-none');

      } else {

        document.getElementById('to-class-card').classList.add('d-none');

      }

      writeSymbology();

    }

    function handleClasDef() {

      if (document.getElementById('to-method').value == 'int_definidos') {

        document.getElementById('to-class-def-list').innerHTML = '';
        let numClasses = document.getElementById('to-class-number').value;

        for (let i = 0; i < numClasses; i++) {

          document.getElementById('to-class-def-list').innerHTML += `
          <div class="row">
            <div class="col">
              <input value="" type="number" class="form-control  form-control-sm" id="to-class-1" placeholder="0">
            </div>
            <div class="col-1">
              -
            </div>
            <div class="col">
              <input value="" type="number" class="form-control  form-control-sm" id="to-class-1" placeholder="0">
            </div>
          </div>
          `


        }
        
      }

    }

    function handleProcBtn() {

      let a = document.getElementById('to-qualitative').checked;
      let b = document.getElementById('to-quantitative').checked;

      let i = document.getElementById('to-layer').value > -1;
      let j = document.getElementById('to-attribute').value > -1;

      if((a || b) && i && j){

        document.getElementById('to-run-btn').disabled = false;

      } else {

        document.getElementById('to-run-btn').disabled = true;

      }

    }


    function writeSymbology() {

      var order = {
        qualitative: ["Set2", "Accent", "Set1", "Set3", "Dark2", "Paired", "Pastel2", "Pastel1"],
        quantitative: ["OrRd", "PuBu", "BuPu", "Oranges", "BuGn", "YlOrBr", "YlGn", "Reds", "RdPu", "Greens", "YlGnBu", "Purples", "GnBu", "Greys", "YlOrRd", "PuRd", "Blues"],
        diverging: ["Spectral", "RdYlGn", "RdBu", "PiYG", "PRGn", "RdYlBu", "BrBG", "RdGy", "PuOr", "PuBuGn"]
      };

      let isQualitative = document.getElementById('to-qualitative').checked;
      let orderName = isQualitative ? 'qualitative' : 'quantitative';
      let classNumber = isQualitative ? 8 : document.getElementById('to-class-number').value;

      let checked = "checked";
      let palletElm = document.getElementById('to-color-pallet');
      palletElm.innerHTML = '';

      for (let i = 0; i < order[orderName].length; i++) {

        let colors = colorbrewer[order[orderName][i]][classNumber];

        if (colors) {

          let data = `
            <div class="color-choose">
              <div class="color-select">
                <input type="radio" name="to-color" value="${order[orderName][i]}" ${checked}>
              </div>
            <div class="color-ramp">`;

          for (let j = 0; j < colors.length; j++) {
            data += `<div style="width: ${100 / classNumber}%; height: 20px; background: ${colors[j]}; float:left;"></div>`;
          }

          data += '</div></div>';

          palletElm.innerHTML += data;
          checked = "";

        }

      }

    }

  }

  activate() {

    this._getData();

    this.show();

  }

  deactivate() {

    this.hide();
  }

}

export { ThematicOgc };