import { GeoButton } from '../core/GeoButton';

class ZoomExtent extends GeoButton {

    constructor(config) {

        super(config);

        config = config || {};
        config.tip = config.tip || 'Aproximar para a extensão';
        this._extent = config.extent || [-180, -90, 180, 90];
        this._srid = config.srid || 'EPSG:4326';
        this._forceInit = config.forceOnInitialize;

    }

    initialize() {

        if (this._forceInit) {
            this.click();
        }
    }

    click() {

        let extent = ol.proj.transformExtent(this._extent, this._srid, this.map.srid);
        this.map.ol.getView().fit(extent);

    }

}

export { ZoomExtent };